import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Header from 'mui-components/header';
import { Box, Tabs, Tab, Grid, Typography } from '@mui/material'
import { TASK_TASKLIST, TASK_BATCH } from 'constants/index';
import TaskList from 'containers/tasks/tasklist';
import TaskBatch from 'containers/tasks/batch';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={index === 0 ? 'customFieldTabDiv' : 'customFertilizerTabDiv'}
    >
      {value === index && (
        <Box sx={{ p: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class Tasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      value: 0
    };
  }

  componentDidMount = () => {
    const { location, activeFarm, history } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (location.pathname.includes(TASK_BATCH)) {
      this.setState({
        selected: TASK_BATCH,
        value: 1
      });
    } else {
      this.setState({
        selected: TASK_TASKLIST,
        value: 0
      });
    }
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
    const { history } = this.props;    
    if(newValue === 1) {
      this.setState({ selected: TASK_BATCH, value: newValue });
      history.push("/tasks/batch");
    } else {
      this.setState({ selected: TASK_TASKLIST, value: newValue });
      history.push("/tasks");
    }
  };

  render() {
    const { intl } = this.props;
    const { value } = this.state;
    const tabStyle = { 
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      color: '#00696D',
      textTransform: 'none',
      padding: '0px 30px'
    };
    return (
      <div className="main-content">
        <Header bcTitle={intl.formatMessage({ id: 'TASKS' })} />
        <div className="settings-tabs">
          <Box sx={{ borderBottom: 1, borderColor: 'divider', flexGrow: 1 }}>
            <Grid container justifyContent="flex-end">
              <Grid item md='12'>
                <Tabs variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  value={value} onChange={this.handleChange} className='mui-tabs'>
                  <Tab style={tabStyle} label={intl.formatMessage({ id: 'TASK_TASKLIST' })} {...a11yProps(0)} />
                  <Tab style={tabStyle} label={intl.formatMessage({ id: 'TASK_BATCH' })} {...a11yProps(1)} sx={{wordSpacing:"15%"}} />
                </Tabs>
              </Grid>
            </Grid>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <TaskList />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <TaskBatch />
          </CustomTabPanel>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm
});
export default injectIntl(withRouter(connect(mapStateToProps)(Tasks)));