import * as React from 'react';
import { Box, Typography, List, ListItemButton, Stack, ListItemText, Collapse, Grid } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import { 
  kpiBox,
  kpiBoxHeader,
  kpiBoxTypographyTitle,
  kpiBoxTypographySubTitle,
  kpiBoxTypographySubTitle1,
  listTitle,
  listSubTitle,
  listFieldCount,
  listArrayName,
  listArrayValue,
  gridStyle,
  lightGreyTitle,
  percentageTitle,
  percentageSubTitle,
  toolTipText1,
  toolTipText2,
  textHarvestStyle
} from 'mui-components/stats/NitrogenKpiCardCss';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    minWidth: '464px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    borderRadius: '12px',
    background: '#ECEEEE',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)'
  },
}));

export default ({
  open1, open2, open3, handleClick, intl, nitrogenListModal, data }) => (
  (data && data.fertilizationEfficiency && data.fertilizationEfficiency.allFieldsWithFertilization && data.fertilizationEfficiency.allFieldsWithFertilization.length > 0 ?
  <Box style={kpiBox}>
    <Box style={kpiBoxHeader} sx={{ padding: '10px 0px' }}>
      <Typography sx={{textAlign: 'end', paddingRight: '10px', lineHeight: 0 }}>
        <HtmlTooltip
          title={
            <React.Fragment>
              <Box sx={{ padding: '12px 16px' }}>
                <Typography style={toolTipText1}>Nitrogenregnskap</Typography>
                <Typography style={toolTipText2} sx={{ paddingTop: '16px' }}>Nitrogenbalanse kg/daa = Nitrogen påført - Nitrogen i avling</Typography>
                <Typography style={toolTipText2} sx={{ paddingTop: '16px' }}>Nitrogenutnyttelse = (Nitrogen i avling / Nitrogen påført) x 100</Typography>
              </Box>
            </React.Fragment>
          }
        >
          <InfoIcon />
        </HtmlTooltip>
      </Typography>
      <Typography style={kpiBoxTypographyTitle}>{intl.formatMessage({ id: 'NITROGEN_KPI_TEXT1' })}</Typography>
      <Typography style={kpiBoxTypographySubTitle}>{intl.formatMessage({ id: 'NITROGEN_KPI_TEXT2' })}</Typography>
    </Box>
    <Box style={kpiBoxHeader} sx={{ cursor: 'pointer', padding: '45px 0px' }} onClick={e => nitrogenListModal()}>
      <Typography style={lightGreyTitle}>{intl.formatMessage({ id: 'NITROGEN_KPI_TEXT3' })}</Typography>
      <Typography style={percentageTitle}>{data.fertilizationEfficiency.averageUtilizationPercent}%</Typography>
      <Typography style={percentageSubTitle}>{intl.formatMessage({ id: 'NITROGEN_KPI_TEXT4' })}</Typography>
    </Box>
    <Box style={kpiBoxHeader}>
      <List
        sx={{ paddingTop: '0', paddingBottom: '20px' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton
          disableRipple
          sx={{ 
            '&:hover': { backgroundColor: 'transparent' }, 
            display: 'flex', 
            justifyContent: 'space-between', // Ensure spacing between text and icon
            alignItems: 'center', // Vertically align content
            paddingLeft: '20px'
          }}
          onClick={e => handleClick(e, 1)}
        >
          <ListItemText 
            primary={<Stack style={listTitle}>{intl.formatMessage({ id: 'NITROGEN_KPI_TEXT5' })}</Stack>}
            secondary={<Stack style={listSubTitle}>{intl.formatMessage({ id: 'NITROGEN_KPI_TEXT6' })}</Stack>} 
            sx={{ flexGrow: 1 }} // Pushes the text to take available space
          />
          <Stack sx={{ paddingRight: '20px' }} style={listFieldCount}>
            {data.fertilizationEfficiency.fieldsWithOverFertilization.length} {intl.formatMessage({ id: 'NITROGEN_KPI_TEXT7' })}
          </Stack>
          <Stack>
            {open1 ? <ExpandLess /> : <ExpandMore />}
          </Stack>
        </ListItemButton>
        <Collapse in={open1} timeout="auto" unmountOnExit>
          <List>
            <ListItemButton disableRipple sx={{ paddingTop: 1, paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'}, }}>  
              <Box
                sx={{
                  width: "95%",
                  margin: "0 auto",
                }}
              >
                {data.fertilizationEfficiency.fieldsWithOverFertilization.length > 0 && data.fertilizationEfficiency.fieldsWithOverFertilization.map((fields) => (
                <Grid container spacing={2} style={gridStyle}>
                  <Grid item xs={6}>
                    <Typography style={listArrayName}>{fields.name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={listArrayValue}>{fields.nbalance} kg/daa</Typography>
                  </Grid>
                </Grid>))}
              </Box>
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton
          disableRipple
          sx={{ 
            '&:hover': { backgroundColor: 'transparent' }, 
            display: 'flex', 
            justifyContent: 'space-between', // Ensure spacing between text and icon
            alignItems: 'center', // Vertically align content
          }}
          onClick={e => handleClick(e, 2)}
        >
          <ListItemText 
            primary={<Stack style={listTitle}>{intl.formatMessage({ id: 'NITROGEN_KPI_TEXT8' })}</Stack>}
            secondary={<Stack style={listSubTitle}>{intl.formatMessage({ id: 'NITROGEN_KPI_TEXT9' })}</Stack>} 
            sx={{ flexGrow: 1 }} // Pushes the text to take available space
          />
          <Stack sx={{ paddingRight: '20px' }} style={listFieldCount}>
            {data.fertilizationEfficiency.fieldsWithUnderFertilization.length} {intl.formatMessage({ id: 'NITROGEN_KPI_TEXT7' })}
          </Stack>
          <Stack>
            {open2 ? <ExpandLess /> : <ExpandMore />}
          </Stack>
        </ListItemButton>
        <Collapse in={open2} timeout="auto" unmountOnExit>
          <List component="div" className='list-grid-box'>
            <ListItemButton disableRipple sx={{ paddingTop: 1, paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'}, }}>  
              <Box
                sx={{
                  width: "95%",
                  margin: "0 auto",
                }}
              >
                {data.fertilizationEfficiency.fieldsWithUnderFertilization.length > 0 && data.fertilizationEfficiency.fieldsWithUnderFertilization.map((fields) => (
                <Grid container spacing={2} style={gridStyle}>
                  <Grid item xs={6}>
                    <Typography style={listArrayName}>{fields.name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={listArrayValue}>{fields.nbalance} kg/daa</Typography>
                  </Grid>
                </Grid>))}
              </Box>
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton 
          disableRipple
          sx={{ '&:hover': { backgroundColor: 'transparent', cursor: 'default' }}}
        >
          <Box sx={{ borderBottom: '1px solid #BEC8C9', width: '100%', paddingBottom: '10px' }} />
        </ListItemButton>

        <ListItemButton
          disableRipple
          sx={{ 
            '&:hover': { backgroundColor: 'transparent' }, 
            display: 'flex', 
            justifyContent: 'space-between', // Ensure spacing between text and icon
            alignItems: 'center', // Vertically align content
            paddingTop: '20px'
          }}
          onClick={e => handleClick(e, 3)}
        >
          <Stack sx={{ paddingRight: '20px' }} style={listFieldCount}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 17V15H13V17H11ZM11 7V13H13V7H11Z" fill="#BA1A1A"/>
            </svg>
          </Stack>
          <ListItemText 
            primary={<Stack style={listTitle}>Mangler analyse</Stack>}
            sx={{ flexGrow: 1 }} // Pushes the text to take available space
          />
          <Stack sx={{ paddingRight: '20px' }} style={listFieldCount}>
            {data.fertilizationEfficiency.fieldsWithInsufficientData.length} {intl.formatMessage({ id: 'NITROGEN_KPI_TEXT7' })}
          </Stack>
          <Stack>
            {open3 ? <ExpandLess /> : <ExpandMore />}
          </Stack>
        </ListItemButton>
        <Collapse in={open3} timeout="auto" unmountOnExit>
          <List component="div" className='list-grid-box'>
            <ListItemButton disableRipple sx={{ paddingTop: 1, paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'}, }}>  
              <Box
                sx={{
                  width: "95%",
                  margin: "0 auto",
                }}
              >
                {data.fertilizationEfficiency.fieldsWithInsufficientData.length > 0 && data.fertilizationEfficiency.fieldsWithInsufficientData.map((fields) => (
                <Grid container spacing={2} style={gridStyle}>
                  <Grid item xs={6}>
                    <Typography style={listArrayName}>{fields.name}</Typography>
                    <Typography style={textHarvestStyle}>
                      {fields.harvests.length > 0 && fields.harvests.map((harvest, index) => (
                        `${harvest}. slått${index+1 < fields.harvests.length ? ', ' : '' }` 
                      ))}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={listArrayValue}>-</Typography>
                    <Typography style={listArrayValue}>&nbsp;</Typography>
                  </Grid>
                </Grid>))}
              </Box>
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </Box>
  </Box> :
  <Box style={kpiBox}>
    <Box style={kpiBoxHeader} sx={{ padding: '10px 0px' }}>
      <Typography sx={{textAlign: 'end', paddingRight: '10px', lineHeight: 0 }}>
        <HtmlTooltip
          title={
            <React.Fragment>
              <Box sx={{ padding: '12px 16px' }}>
                <Typography style={toolTipText1}>Nitrogenregnskap</Typography>
                <Typography style={toolTipText2} sx={{ paddingTop: '16px' }}>Nitrogenbalanse kg/daa = Nitrogen påført - Nitrogen i avling</Typography>
                <Typography style={toolTipText2} sx={{ paddingTop: '16px' }}>Nitrogenutnyttelse = (Nitrogen i avling / Nitrogen påført) x 100</Typography>
              </Box>
            </React.Fragment>
          }
        >
          <InfoIcon />
        </HtmlTooltip>
      </Typography>
      <Typography style={kpiBoxTypographyTitle}>{intl.formatMessage({ id: 'NITROGEN_KPI_TEXT1' })}</Typography>
      <Typography style={kpiBoxTypographySubTitle}>{intl.formatMessage({ id: 'NITROGEN_KPI_TEXT2' })}</Typography>
    </Box>
    <Box style={kpiBoxHeader} sx={{ padding: '45px 0px' }} onClick={e => nitrogenListModal()}>
      <Typography style={percentageTitle}>
        <img src="images/nitrogenEmpty.png" alt="" />
      </Typography>
    </Box>
    <Box style={kpiBoxHeader} sx={{padding: '0px 30px 30px 30px'}}>
      <Typography style={kpiBoxTypographySubTitle1}>{intl.formatMessage({ id: 'NITROGEN_KPI_TEXT18' })}</Typography>
    </Box>
  </Box>)
);
