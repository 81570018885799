import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import HistoricalListCard from 'mui-components/fields/fieldtab/historical/HistoricalListCard';
import { CssGridLayout } from '@tine/lib-frontend-components/components/alignment/css-grid-layout/css-grid-layout';
import * as historicalListActions from 'actions/HistoricalList';
import { NEW } from 'constants/index';
import _ from 'lodash';

class HistoricalList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramId: props.match.params.id,
      updatedFields: false,
      previousIndex: null,
      nextIndex: null,
      previousIndexName: null,
      nextIndexName: null,
      selectedFieldId: null
    };
  }

  componentDidMount = () => {
    const { history, activeFarm, getHistoricalList } = this.props;
    const { paramId } = this.state;
    if (!activeFarm) {
      history.push('/settings');
    } else {
      getHistoricalList(activeFarm.farm.id, paramId);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { history, activeFarm, getHistoricalList, fieldDetails, location } = this.props;
    const { updatedFields, selectedFieldId } = this.state;
    const { paramId } = this.state;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (!updatedFields && fieldDetails && fieldDetails !== 'undefined') {
      getHistoricalList(activeFarm.farm.id, selectedFieldId !== null ? selectedFieldId : paramId);
      this.intializeValue(selectedFieldId !== null ? selectedFieldId : paramId);
    }
    if (location !== prevProps.location) {
      getHistoricalList(activeFarm.farm.id, selectedFieldId !== null ? selectedFieldId : paramId);
      this.intializeValue(selectedFieldId !== null ? selectedFieldId : paramId);
    }
    if (activeFarm !== prevProps.activeFarm) {
      history.push(`/managefields/${paramId}`);
      getHistoricalList(activeFarm.farm.id, paramId);
    }
  };

  // Intialize compoenent values by passing url param Id
  intializeValue = paramId => {
    const { fieldDetails } = this.props;
    this.setState({ updatedFields: true });
    if (paramId !== NEW) {
      const currentField = _.filter(fieldDetails, ['fieldId', paramId]);
      const currentIndex = fieldDetails.findIndex( fieldDetail => fieldDetail.fieldId === paramId );
      if (currentField.length > 0) {
        if(currentIndex === 0 && currentIndex === fieldDetails.length-1) {
          this.setState({ 
            previousIndex: null,
            nextIndex: null,
            previousIndexName: null,
            nextIndexName: null
          });
        }
        if(currentIndex === 0 && currentIndex !== fieldDetails.length-1) {
          this.setState({ 
            previousIndex: null,
            nextIndex: fieldDetails[currentIndex+1].fieldId,
            previousIndexName: null,
            nextIndexName: fieldDetails[currentIndex+1].fieldName
          });
        }
        if(currentIndex !== 0 && currentIndex === fieldDetails.length-1) {
          this.setState({ 
            previousIndex: fieldDetails[currentIndex-1].fieldId,
            nextIndex: null,
            previousIndexName: fieldDetails[currentIndex-1].fieldName,
            nextIndexName: null
          });
        }
        if(currentIndex !== 0 && currentIndex !== fieldDetails.length-1) {
          this.setState({ 
            previousIndex: fieldDetails[currentIndex-1].fieldId,
            nextIndex: fieldDetails[currentIndex+1].fieldId,
            previousIndexName: fieldDetails[currentIndex-1].fieldName,
            nextIndexName: fieldDetails[currentIndex+1].fieldName
          });
        }
      }
    }
  };

  changeField = (fieldId) => {
    const { history } = this.props;
    this.setState({
      selectedFieldId: fieldId
    });
    history.push(`/managefields/${fieldId}/historical`);
  }

  render() {
    const { activeFarm, historicalList, intl } = this.props;
    const { previousIndex, nextIndex, previousIndexName, nextIndexName, selectedFieldId, paramId } = this.state;
    const historicalListCard = 
      historicalList.historicalListValue?.length >= 1 &&
      historicalList.historicalListValue.map(historical => (
        <div className="full-width" key={historical.year}>
          <div className="full-width field-year">{historical.year}</div>
          <ul className="full-width">
            {historical.tasks.map(history => (
            <HistoricalListCard
              historical={history}
              farm={activeFarm}
              key={history.id}
              disable={historicalList.historicalListLoading}
              selectedFieldId={selectedFieldId !== null ? selectedFieldId : paramId}
            />))}
          </ul>
        </div>
      ));
    return (
      <div>
        <div className="field-prev-next-css2 prev-next-float-left">
          {previousIndex !== null ?
          <span className="field-prev-css">
            <span className="field-prev-next-link"
              onClick={e => {
              this.changeField(previousIndex);
            }}>
              {/*intl.formatMessage({ id: 'PREVIOUS_FIELD' })*/}
              {previousIndexName}
            </span>
          </span> :
          <span className="field-prev-css field-prev-next-link-disable">
            {intl.formatMessage({ id: 'PREVIOUS_FIELD' })}
          </span>}
          {nextIndex !== null ?
          <span>
            <span className="field-prev-next-link"
              onClick={e => {
              this.changeField(nextIndex);
            }}>
              {nextIndexName}
              {/*intl.formatMessage({ id: 'NEXT_FIELD' })*/}
            </span>
          </span> :
          <span className="field-prev-next-link-disable">
            {intl.formatMessage({ id: 'NEXT_FIELD' })}
          </span>}
        </div>
        <div>
          {historicalList.historicalListValue && historicalList.historicalListValue.length === 0 &&
            <p className="no-fields-text full-width">
              <FormattedMessage id="NO_TASK_FOUND" />
            </p>
          }
          {historicalList.historicalListValue && historicalList.historicalListValue.length >= 1 &&
            <div className="grass-list-container full-width">
              <CssGridLayout>
                <ul className="grass-list-wrapper full-width">{historicalListCard}</ul>
              </CssGridLayout>
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  userDetails: state.userDetails.data,
  historicalList: state.historicalList,
  fieldDetails: state.fieldDetails.data
});

const mapDispatchToProps = dispatch => ({
  getHistoricalList: (farmId, fieldId) => dispatch(historicalListActions.getHistoricalList(farmId, fieldId))
});

export default injectIntl(
  reduxForm({
    form: 'HistoricalList',
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoricalList)))
);
