import * as React from 'react';
import MuiTextBox from 'components/mui-forms/MuiTextBox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';
import {
  getDropDownFieldsStatuses,
  getYears
} from 'utils/index';
import MuiSelectBox from 'components/mui-forms/MuiSelectBox';
import { Stack, Grid, FormControl, Radio, RadioGroup, FormControlLabel, List, ListItemButton, Collapse } from '@mui/material'

const CropComponent = ({ lblPage, stravRemovedApplied, intl, disableGrain, fieldOptionApplied, fieldMasterData, open1,
  fieldCrop, previousCrop, seedingYear, clover, handleClickCrop, handleChange, onCropChange, onFieldOptionChangeTrue, 
  onFieldOptionChangeFalse, onStravRemovedChangeTrue, onStravRemovedChangeFalse}) => (
  <List
    sx={{ bgcolor: 'background.paper', paddingTop: '0', paddingBottom: '0' }}
    component="nav"
    aria-labelledby="nested-list-subheader"
  >
    <ListItemButton disableRipple sx={{ '&:hover': {backgroundColor: 'transparent'} }} onClick={handleClickCrop}>
      <span><ListItemText primary={intl.formatMessage({ id: 'CT_CR' })}/></span>
      <span>{open1 ? <ExpandLess /> : <ExpandMore />}</span>
    </ListItemButton>
    <Collapse in={open1} timeout="auto" unmountOnExit>
      <List component="div" className='list-grid-box'>
        <ListItemButton disableRipple sx={{ width: '40%',  paddingTop: 4, paddingBottom: 0, '&:hover': {backgroundColor: 'transparent'}, '&:focused': {backgroundColor: 'transparent'} }}>
          <Grid container justifyContent="flex-end">
            <Grid item md={12}>
              <Stack className='text-with-padding'>
                <MuiSelectBox 
                  name="fieldCrop"
                  id="fieldCrop"
                  label={lblPage.labels.crop}
                  variant="outlined"
                  value={fieldCrop}
                  options={getDropDownFieldsStatuses(fieldMasterData.cropList)}
                  onChange={onCropChange}
                />
              </Stack>
              <Stack className='text-with-padding'>
                <MuiSelectBox 
                  name="previousCrop"
                  id="previousCrop"
                  label={lblPage.labels.previouscrop}
                  variant="outlined"
                  value={previousCrop}
                  options={getDropDownFieldsStatuses(disableGrain === true ? fieldMasterData.previousCropList : fieldMasterData.cropList)}
                  onChange={handleChange}
                />
              </Stack>
              <Stack className='text-with-padding'>
                <MuiSelectBox 
                  name="seedingYear"
                  id="seedingYear"
                  label={lblPage.labels['seeding year']}
                  variant="outlined"
                  value={seedingYear}
                  options={getYears(1970)}
                  onChange={handleChange}
                />
              </Stack>
              <Stack className='text-with-padding'>
                <MuiTextBox 
                  name="clover"
                  id="clover"
                  label={lblPage.labels.clover}
                  variant="outlined"
                  value={clover}
                  onChange={handleChange}
                  showFullWidth="true"
                />
              </Stack>
              <Stack className='text-with-padding'>
                <FormControl>
                  <Stack sx={{color: '#6F7979'}}>{intl.formatMessage({ id: 'FIELD_ORGANIC_LABEL' })}</Stack>
                  <RadioGroup row >
                    <FormControlLabel
                      id="field_option_yes"
                      onChange={onFieldOptionChangeTrue}
                      label={lblPage.labels.yes}
                      name="fieldOptionApplied"
                      size="small"
                      checked={fieldOptionApplied === true}
                      control={
                        <Radio
                          sx={{
                            color: '#6F7979',
                            '&.Mui-checked': {
                              color: '#00696D',
                              fontFamily: 'Inter'
                            }
                          }}
                        />}
                    />
                    <FormControlLabel
                      id="field_option_no"
                      onChange={onFieldOptionChangeFalse}
                      label={lblPage.labels.no}
                      name="fieldOptionApplied"
                      size="small"
                      checked={fieldOptionApplied === false}
                      control={
                        <Radio
                          sx={{
                            color: '#6F7979',
                            '&.Mui-checked': {
                              color: '#00696D',
                              fontFamily: 'Inter'
                            }
                          }}
                        />}
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
              {disableGrain === true && 
              <Stack className='text-with-padding'>
                <FormControl>
                  <Stack sx={{color: '#6F7979'}}>{intl.formatMessage({ id: 'REMOVAL_OF_STRAV' })}</Stack>
                  <RadioGroup row >
                    <FormControlLabel
                      id="strav_removed_yes"
                      onChange={onStravRemovedChangeTrue}
                      label={lblPage.labels.yes}
                      name="stravRemovedApplied"
                      size="small"
                      checked={stravRemovedApplied === true}
                      control={
                        <Radio
                          sx={{
                            color: '#6F7979',
                            '&.Mui-checked': {
                              color: '#00696D',
                              fontFamily: 'Inter'
                            }
                          }}
                        />}
                    />
                    <FormControlLabel
                      id="strav_removed_no"
                      onChange={onStravRemovedChangeFalse}
                      label={lblPage.labels.no}
                      name="stravRemovedApplied"
                      size="small"
                      checked={stravRemovedApplied === false}
                      control={
                        <Radio
                          sx={{
                            color: '#6F7979',
                            '&.Mui-checked': {
                              color: '#00696D',
                              fontFamily: 'Inter'
                            }
                          }}
                        />}
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>}
            </Grid>
          </Grid>
        </ListItemButton>
      </List>
    </Collapse>
  </List>
);

export default CropComponent;