export const kpiBox = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '12px',
  border: '1px solid var(--M3-sys-light-surface-container-high, #E6E9E8)',
  background: '#F7FAF9'
}
export const kpiBoxHeader = {
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '4px',
  width: '100%'
}
export const kpiBoxTypographyTitle = {
  color: '#191C1C',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  display: 'block'
}
export const kpiBoxTypographySubTitle = {
  ...kpiBoxTypographyTitle,
  fontSize: '14px',
  fontWeight: '400',
  letterSpacing: '0.25px'
}
export const kpiBoxTypographySubTitle1 = {
  color: 'var(--M3-sys-light-on-surface, #191C1C)',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '16px'
}
export const listTitle = {
  ...kpiBoxTypographyTitle,
  textAlign: 'left',
  fontSize: '16px',
  fontWeight: '400',
  letterSpacing: '0.5px'
}
export const listSubTitle = {
  ...kpiBoxTypographyTitle,
  overflow: 'hidden',
  color: '#3F4949',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'left',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '0.25px'
}
export const listFieldCount = {
  ...kpiBoxTypographyTitle,
  textAlign: 'right',
  color: '#00696D',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: '11px',
  fontWeight: '500',
  lineHeight: '16px',
  letterSpacing: '0.5px'
}
export const listArrayName = {
  color: "#3F4949",
  textAlign: 'left',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px'
}
export const listArrayValue = {
  ...listArrayName,
  textAlign: 'right',
  fontSize: '11px',
  fontWeight: '500',
  lineHeight: '16px'
}
export const gridStyle = {
  display: 'flex',
  padding: '0px 34px 25px 16px',
  alignItems: 'center',
  flex: '1 0 0',
  alignSelf: 'stretch'
}
export const lightGreyTitle = {
  ...kpiBoxTypographyTitle,
  color: '#6F7979',
  fontSize: '11px',
  lineHeight: '16px',
  letterSpacing: '0.5px'
}
export const percentageTitle = {
  ...kpiBoxTypographyTitle,
  fontSize: '28px',
  fontWeight: '400',
  lineHeight: '45px'
}
export const percentageSubTitle = {
  ...kpiBoxTypographyTitle,
  fontSize: '11px',
  lineHeight: '16px',
  letterSpacing: '0.5px'
}
export const toolTipText1 = {
  color: '#3F4949',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '20px',
  letterSpacing: '0.1px'
}
export const toolTipText2 = {
  ...toolTipText1,
  fontWeight: '400',
  letterSpacing: '0.25px'
}
export const dialogTitleBox = {
  padding: '8px 4px',
  alignItems: 'center',
  gap: '4px',
  alignSelf: 'stretch'
}
export const gridDialogStyle = {
  alignItems: 'center'
}
export const gridDialogTitle = {
  color: '#191C1C',
  fontFamily: 'Inter',
  fontSize: '22px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '28px',
  padding: '8px 0px'
}
export const buttonFilledLeftStyle = {
  borderTopLeftRadius: '100px',
  borderBottomLeftRadius: '100px',
  border: '1px solid #BEC8C9',
  width: '110px'
}
export const buttonFilledLeftStyle1 = {
  borderTopLeftRadius: '100px',
  borderBottomLeftRadius: '100px',
  border: '1px solid #BEC8C9',
  backgroundColor: '#BFD9DB',
  width: '110px'
  
}
export const buttonFilledRightStyle = {
  borderTopRightRadius: '100px',
  borderBottomRightRadius: '100px',
  border: '1px solid #BEC8C9',
  width: '110px'
}
export const buttonFilledRightStyle1 = {
  borderTopRightRadius: '100px',
  borderBottomRightRadius: '100px',
  border: '1px solid #BEC8C9',
  backgroundColor: '#BFD9DB',
  width: '110px'
}
export const titleTextButton = {
  color: '#191C1C',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  textTransform: 'Capitalize'
}
export const gridButton = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
  padding: '20px 0px'
}
export const chipStyleSelected = {
  display: 'flex',
  height: '32px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  border: '1px solid var(--M3-sys-light-outline-variant, #BEC8C9)',
  padding: '6px 16px',
  gap: '8px',
  background: '#CCE8E9',
  color: '#3F4949',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '20px',
  letterSpacing: '0.1px'
}
export const chipStyleNotSelected = {
  display: 'flex',
  height: '32px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  border: '1px solid var(--M3-sys-light-outline, #6F7979)',
  padding: '6px 16px',
  background: '#FFFFFF',
  gap: '8px',
  color: '#3F4949',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '20px',
  letterSpacing: '0.1px'
}
export const gridContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'      
};
export const tableHeading = {
  padding: '20px',
  borderRight: '1px solid #BEC8C9',
  minWidth: '28%',
  maxWidth: '28%',
  textAlign: 'left'
}
export const tableContent = {
  padding: '20px',
  minWidth: '18%',
  maxWidth: '18%',
  textAlign: 'left'
}
export const textStyle = {
  color: 'var(--M3-sys-light-on-surface, #191C1C)',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px'
}
export const textSubStyle = {
  overflow: 'hidden',
  color: 'var(--M3-sys-light-on-surface-variant, #49454F)',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  letterSpacing: '0.25px'
}
export const textHarvestStyle = {
  overflow: 'hidden',
  color: 'var(--M3-sys-light-on-surface-variant, #3F4949)',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontFamily: 'Inter',
  fontsize: '14px',
  fontStyle: 'normal',
  fontWeight: '400;',
  lineHeight: '20px',
  letterSpacing: '0.25px',
}