import React, { createRef } from 'react';

import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import CheckboxControl from 'components/forms/CheckboxControl';
import * as fieldChartActions from 'actions/FieldChart';
import SelectControl from 'components/forms/SelectBoxControl';
import { Field } from 'redux-form';
import { getDropDownWeatherStations } from 'utils/index';
import Loader from 'components/Loader';
import { NEW } from 'constants/index';
import _ from 'lodash';
import { Grid, Card, Box, Typography, CardContent, Stack, Dialog, DialogTitle, Chip, Radio, RadioGroup, FormControl, FormControlLabel, ToggleButton, 
  ToggleButtonGroup, MenuItem, Button, Popper, Grow, Paper, MenuList, ClickAwayListener, ListItemText, Checkbox } from '@mui/material'
import { buttonStyle } from 'mui-components/fields/fertilization/services/fertilizationStyles';
import MuiMultiSelect from 'components/mui-forms/MuiMultiSelect';
import MuiRadioButton from 'components/mui-forms/MuiRadioButton';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import {
  TEMPERATURE_SUM_COLOR_LIST, MEAN_TEMPERATURE_COLOR_LIST, DAILY_RAINFALL_COLOR_LIST, TEMPERATURE_SUM_YAXIS_DEFAULT, 
  MEAN_TEMPERATURE_YAXIS_DEFAULT, MEAN_TEMPERATURE_YAXIS_DEFAULT2, TEMPERATURE_TITLE_STYLE, TEMPERATURE_SUM_MIN_VALUE, 
  TEMPERATURE_SUM_MAX_VALUE, MEAN_TEMPERATURE_MIN_VALUE, MEAN_TEMPERATURE_MAX_VALUE, XAXIS_TICK_AMOUNT, YAXIS_TICK_AMOUNT, 
  OPTIONS_CHART, OPTIONS_XAXIS_LABELS, OPTIONS_XAXIS_TITLE_STYLE, DISCRETE_COLOR, DISCRETE_OMD_COLOR, RAPROTEIN_COLOR_LIST, OMD_COLOR_LIST
} from 'constants/FieldChart';
import mixpanel from 'mixpanel-browser';
import { ENTER_WEATHER_TAB } from 'constants/MixPanel';
import WeatherHeader from 'mui-components/fields/fieldtab/weather/WeatherHeader';
import WeatherTab2 from 'mui-components/fields/fieldtab/weather/WeatherTab2';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

class Weather extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramId: props.match.params.id,
      series: null,
      chart2Series: null,
      options: null,
      chart2Options: null,
      displayChart: false,
      chart2Display: false,
      chartYearChecked: new Map(),
      chartGraphChecked: new Map(),
      tempSumLegendColor: new Map(),
      meanTempLegendColor: new Map(),
      dailyRainfallLegendColor: new Map(),
      raProteinLegendColor: new Map(),
      omdLegendColor: new Map(),
      customColors: null,
      loadingChart: false,
      strokeDashArray: null,
      discreteArray: null,
      discreteOmdArray: null,
      markerSizeArray: null,
      yAxisSeries: null,
      chart2CustomColors: null,
      chart2LoadingChart: false,
      chart2StrokeDashArray: null,
      chart2DiscreteArray: null,
      chart2MarkerSizeArray: null,
      chart2yAxisSeries: null,
      chart2xAxisSeries: null,
      onChangeStation: false,
      temperatureSumColor: TEMPERATURE_SUM_COLOR_LIST,
      meanTemperatureColor: MEAN_TEMPERATURE_COLOR_LIST,
      dailyRainfallColor: DAILY_RAINFALL_COLOR_LIST,
      raProteinColor: RAPROTEIN_COLOR_LIST,
      omdColor: OMD_COLOR_LIST,
      updatedFields: false,
      previousIndex: null,
      nextIndex: null,
      previousIndexName: null,
      nextIndexName: null,
      selectedFieldId: null,
      openOmdBox: false,
      omdGraphValue: null,
      selectedFieldCheckbox: [],
      harvestFieldValues: [],
      omdErrorMessage: false,
      selectAllCheckbox: false,
      selectedYearValues: [],
      selectedValue: 'first',
      harvest1State: true,
      harvest2State: true,
      harvest3State: true,
      harvest4State: true,
      selectSeasonState: 1,
      selectStationState: null,
      openDialogGraph: false,
      harvestStateValues: []
    };
    this.openOmdDialogBox = this.openOmdDialogBox.bind(this);
    this.anchorRef = createRef();
  }

  componentDidMount() {
    const { activeFarm, history, userDetails, fetchWeatherData } = this.props;
    const { paramId, selectedFieldId } = this.state;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (paramId !== NEW) {
      const formDataObject = { "fieldId": (selectedFieldId !== null ? selectedFieldId : paramId), "celciusDaysTarget": 475 };
      fetchWeatherData(activeFarm.farm.id, formDataObject);
    }
    mixpanel.identify(userDetails.id);
    mixpanel.track(ENTER_WEATHER_TAB);
    this.intialize();
  }

  componentDidUpdate(prevProps) {
    const { activeFarm, history, fieldChart, fieldDetails, location } = this.props;
    const { updatedFields, selectedFieldId } = this.state;
    const { onChangeStation, paramId } = this.state;
    if (!updatedFields && fieldDetails && fieldDetails !== 'undefined') {
      this.intializeValue(selectedFieldId !== null ? selectedFieldId : paramId);
    }
    if (location !== prevProps.location) {
      this.intializeValue(selectedFieldId !== null ? selectedFieldId : paramId);
    }
    if (prevProps.activeFarm !== activeFarm) {
      history.push('/managefields');
    }
    this.intialize();
    if(onChangeStation && fieldChart.weatherData != null) {
      this.setState({
        loadingChart: true,
        customColors: null
      });
      setTimeout(() => {  this.updateChartSeries(); }, 100);
      this.setState({
        onChangeStation: false
      });
    }
  }

  handleToggle = () => {
    this.setState((prevState) => ({ openDialogGraph: !prevState.openDialogGraph }));
  };

  handleClose = (event) => {
    if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
      return;
    }

    this.setState({ openDialogGraph: false });
  };

  handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({ openDialogGraph: false });
    } else if (event.key === 'Escape') {
      this.setState({ openDialogGraph: false });
    }
  };

  handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      this.setState ({
        selectedValue: newAlignment
      });
    }
  };

  // Intialize compoenent values by passing url param Id
  intializeValue = paramId => {
    const { fieldDetails, fieldChart } = this.props;
    fieldChart.omdData = null;
    this.setState({ updatedFields: true });
    this.setState({
      harvest1State: true,
      harvest2State: true,
      harvest3State: true,
      harvest4State: true
    });

    if (paramId !== NEW) {
      const currentField = _.filter(fieldDetails, ['fieldId', paramId]);
      let fieldDetailsSorted = _.orderBy(fieldDetails, [fieldData => fieldData.fieldName.toLowerCase()], ['asc']);
      const currentIndex = fieldDetailsSorted.findIndex( fieldDetail => fieldDetail.fieldId === paramId );
      if (currentField.length > 0) {
        this.setState({
          selectedField: currentField[0],
          currentFieldValue: {...currentField[0]}
        });
        if(currentIndex === 0 && currentIndex === fieldDetailsSorted.length-1) {
          this.setState({ 
            previousIndex: null,
            nextIndex: null,
            previousIndexName: null,
            nextIndexName: null
          });
        }
        if(currentIndex === 0 && currentIndex !== fieldDetailsSorted.length-1) {
          this.setState({ 
            previousIndex: null,
            nextIndex: fieldDetailsSorted[currentIndex+1].fieldId,
            previousIndexName: null,
            nextIndexName: fieldDetailsSorted[currentIndex+1].fieldName
          });
        }
        if(currentIndex !== 0 && currentIndex === fieldDetailsSorted.length-1) {
          this.setState({ 
            previousIndex: fieldDetailsSorted[currentIndex-1].fieldId,
            nextIndex: null,
            previousIndexName: fieldDetailsSorted[currentIndex-1].fieldName,
            nextIndexName: null
          });
        }
        if(currentIndex !== 0 && currentIndex !== fieldDetailsSorted.length-1) {
          this.setState({ 
            previousIndex: fieldDetailsSorted[currentIndex-1].fieldId,
            nextIndex: fieldDetailsSorted[currentIndex+1].fieldId,
            previousIndexName: fieldDetailsSorted[currentIndex-1].fieldName,
            nextIndexName: fieldDetailsSorted[currentIndex+1].fieldName
          });
        }
      }
    }
  };

  /**  */
  intialize = () => {
    let discreteValues = [];
    let discreteOmdValues = [];
    const { fieldChart } = this.props;
    const { displayChart, temperatureSumColor, dailyRainfallColor, chartYearChecked, chartGraphChecked,
      tempSumLegendColor, dailyRainfallLegendColor, selectStationState } = this.state;
    if(fieldChart && fieldChart.weatherData !== null && fieldChart.weatherData.stations !== null && selectStationState === null) {
      this.setState({
        selectStationState: fieldChart.weatherData.stations[0].stationId
      });
    }
    if(fieldChart.weatherData !== null && fieldChart.weatherData.temperatureSumChart !== null && !displayChart ) {
      let harvestStateValuesArray = [];
      //eslint-disable-next-line
      {fieldChart.weatherData && fieldChart.weatherData.temperatureSumChart && fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
        seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (
        chartYearChecked.get(seriesVal.name) &&
        (
          this.addUniqueValue(harvestStateValuesArray, seriesHarvestData.harvest)
        )
      ))))}
      this.setState({
        harvestStateValues: harvestStateValuesArray
      });

      let seriesLength = fieldChart.weatherData.temperatureSumChart.series.length;
      let selectedIndexRange = 1;
      if(fieldChart.weatherData.forecastPoints === 0) {
        selectedIndexRange = 0;
      }
      let discreteValuesLength = fieldChart.weatherData.temperatureSumChart.series[selectedIndexRange].harvestDateIndexes ? 
        fieldChart.weatherData.temperatureSumChart.series[selectedIndexRange].harvestDateIndexes.length : 0;
      
      /** Set discrete array for markers */
      if(discreteValuesLength > 0) {
        fieldChart.weatherData.temperatureSumChart.series[selectedIndexRange].harvestDateIndexes.map((harvestDate) => (       
          harvestDate !== -1 ? 
          discreteValues.push(
          {
            seriesIndex: selectedIndexRange,
            dataPointIndex: harvestDate,
            size: 5,
            fillColor: DISCRETE_COLOR,
            strokeColor: DISCRETE_COLOR,
            shape: "circle",
          }) : ''
        ))
      }

      if(fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series[selectedIndexRange].omdIndexMaps && 
        fieldChart.weatherData.temperatureSumChart.series[selectedIndexRange].omdIndexMaps.length > 0) {
        fieldChart.weatherData.temperatureSumChart.series[selectedIndexRange].omdIndexMaps.map(omdIndexMapList => (
          Object.keys(omdIndexMapList).length > 0 ?
            (Object.keys(omdIndexMapList).map(omdIndexMapValue => (
              parseInt(omdIndexMapValue) !== -1 ? 
              discreteOmdValues.push(
              {
                seriesIndex: 0,
                dataPointIndex: parseInt(omdIndexMapValue),
                size: 3,
                fillColor: DISCRETE_OMD_COLOR,
                strokeColor: DISCRETE_OMD_COLOR,
                shape: "circle",
                dataValue: omdIndexMapList[omdIndexMapValue]
              },{
                seriesIndex: selectedIndexRange,
                dataPointIndex: parseInt(omdIndexMapValue),
                size: 3,
                fillColor: DISCRETE_OMD_COLOR,
                strokeColor: DISCRETE_OMD_COLOR,
                shape: "circle",
                dataValue: omdIndexMapList[omdIndexMapValue]
              }) : ''
            ))) : ''
        ));
      }
      /** By default select the current year  */
      if(fieldChart.weatherData.years !== null) {
        let selectedYear = [];
        fieldChart.weatherData.years.map((year, index) => (
          index === 0 ? (chartYearChecked.set(year, true), selectedYear.push(year)) : chartYearChecked.set(year, false)
        ))
        this.setState({
          selectedYearValues: selectedYear
        })
      }

      /** By default select the temperature-sum graph  */
      if(fieldChart.weatherData.graphs !== null) {
        fieldChart.weatherData.graphs.map((graph, index) => (
          (index === 0 || index === 2) &&
          chartGraphChecked.set("graph_"+index, true)
        ))
      }
      if(fieldChart.weatherData.forecastPoints === 0) {
        this.setState({
          series: [
            {
              name: fieldChart.weatherData.temperatureSumChart.series[0].name.toString(), 
              data: fieldChart.weatherData.temperatureSumChart.series[0].data,
              tooltip: ' °C',
              graphType: 'graph_0',
              harvestTempSum: fieldChart.weatherData.temperatureSumChart.series[0].harvestTempSum
            },{
              name: fieldChart.weatherData.dailyRainfallChart.series[0].name.toString(), 
              data: fieldChart.weatherData.dailyRainfallChart.series[0].data,
              tooltip: ' mm',
              graphType: 'graph_1'
            }
          ],
          customColors: [temperatureSumColor[seriesLength-1], dailyRainfallColor[seriesLength-1]],
          tempSumLegendColor: tempSumLegendColor.set(fieldChart.weatherData.temperatureSumChart.series[0].name.toString(), temperatureSumColor[seriesLength-1]), 
          dailyRainfallLegendColor: dailyRainfallLegendColor.set(fieldChart.weatherData.dailyRainfallChart.series[0].name.toString(), dailyRainfallColor[seriesLength-1]), 
          strokeDashArray: [0,0,0,0],
          discreteArray: discreteValues,
          discreteOmdArray: discreteOmdValues,
          markerSizeArray: [1,1,3,3],
          displayChart: true,
          yAxisSeries: [{
            min: TEMPERATURE_SUM_MIN_VALUE,
            max: TEMPERATURE_SUM_MAX_VALUE,
            title: {
              text: fieldChart.weatherData.temperatureSumChart.yaxis.title.text,
              style: TEMPERATURE_TITLE_STYLE
            },
            tickAmount: YAXIS_TICK_AMOUNT,
            forceNiceScale: true
          },
          {
            min: MEAN_TEMPERATURE_MIN_VALUE,
            max: MEAN_TEMPERATURE_MAX_VALUE,
            title: {
              text: fieldChart.weatherData.dailyRainfallChart.yaxis.title.text,
              style: TEMPERATURE_TITLE_STYLE
            },
            opposite: true,
            tickAmount: YAXIS_TICK_AMOUNT,
            forceNiceScale: true
          }]
        });
      } else {
        /*let newHarvestTempSum = [];
        if(fieldChart.weatherData.temperatureSumChart.series[0] && fieldChart.weatherData.temperatureSumChart.series[0].harvestTempSum && 
          fieldChart.weatherData.temperatureSumChart.series[0].harvestTempSum.length > 0) {
            let countArray = 1;
            fieldChart.weatherData.temperatureSumChart.series[0].harvestTempSum.forEach((item, i, array) => {
              if(item !== null) {
                newHarvestTempSum.push(intl.formatMessage({ id: 'TOOLTIP_ETTER_1' }) + countArray + intl.formatMessage({ id: 'TOOLTIP_ETTER_2' }) + ": " + item);
              } else {
                newHarvestTempSum.push(item);
              }
              if(item === 0) {
                countArray = countArray+1;
              }
          });
        }*/
          let harvestStateValuesArray = [];
          //eslint-disable-next-line
          {fieldChart.weatherData && fieldChart.weatherData.temperatureSumChart && fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
            seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (
              chartYearChecked.get(seriesVal.name) &&
              (
                this.addUniqueValue(harvestStateValuesArray, seriesHarvestData.harvest)
              )
          ))))}
          this.setState({
            harvestStateValues: harvestStateValuesArray
          });
        this.setState({
          series: [{
            name: fieldChart.weatherData.temperatureSumChart.series[1].name.toString(),
            data: fieldChart.weatherData.temperatureSumChart.series[0].data,
            tooltip: ' °C',
            graphType: 'graph_0',
            harvestTempSum: fieldChart.weatherData.temperatureSumChart.series[0].harvestTempSum
          },{
            name: fieldChart.weatherData.temperatureSumChart.series[1].name.toString(),
            data: fieldChart.weatherData.temperatureSumChart.series[1].data,
            tooltip: ' °C',
            graphType: 'graph_0',
            harvestTempSum: fieldChart.weatherData.temperatureSumChart.series[1].harvestTempSum
          },{
            name: fieldChart.weatherData.dailyRainfallChart.series[1].name.toString(),
            data: fieldChart.weatherData.dailyRainfallChart.series[0].data,
            tooltip: ' mm',
            graphType: 'graph_1'
          },{
            name: fieldChart.weatherData.dailyRainfallChart.series[1].name.toString(),
            data: fieldChart.weatherData.dailyRainfallChart.series[1].data,
            tooltip: ' mm',
            graphType: 'graph_1'
          }],
          customColors: [temperatureSumColor[seriesLength-2],temperatureSumColor[seriesLength-2],dailyRainfallColor[seriesLength-2],dailyRainfallColor[seriesLength-2]],
          tempSumLegendColor: tempSumLegendColor.set(fieldChart.weatherData.temperatureSumChart.series[1].name.toString(), temperatureSumColor[seriesLength-2]), 
          dailyRainfallLegendColor: dailyRainfallLegendColor.set(fieldChart.weatherData.dailyRainfallChart.series[1].name.toString(), dailyRainfallColor[seriesLength-2]),
          strokeDashArray: [3,0,3,0],
          discreteArray: discreteValues,
          discreteOmdArray: discreteOmdValues,
          markerSizeArray: [1,1,1,1],
          displayChart: true,
          yAxisSeries: [{
            min: TEMPERATURE_SUM_MIN_VALUE,
            max: TEMPERATURE_SUM_MAX_VALUE,
            title: {
              text: fieldChart.weatherData.temperatureSumChart.yaxis.title.text,
              style: TEMPERATURE_TITLE_STYLE
            },
            tickAmount: YAXIS_TICK_AMOUNT,
            forceNiceScale: true
          },
          TEMPERATURE_SUM_YAXIS_DEFAULT,
          {
            min: MEAN_TEMPERATURE_MIN_VALUE,
            max: MEAN_TEMPERATURE_MAX_VALUE,
            title: {
              text: fieldChart.weatherData.dailyRainfallChart.yaxis.title.text,
              style: TEMPERATURE_TITLE_STYLE
            },
            opposite: true,
            tickAmount: YAXIS_TICK_AMOUNT,
            forceNiceScale: true
          },
          MEAN_TEMPERATURE_YAXIS_DEFAULT]
        });
      }
    } 
  }

  openOmdDialogBox = () => {
    const { fieldChart, fieldList } = this.props;
    const { paramId, selectedFieldId } = this.state;
    let selectBox = true;
    if(fieldChart.omdData !== null) {
      this.setState({
        harvestFieldValues: fieldChart.omdData && fieldChart.omdData.fields
      });
    } else {
      this.setState({
        harvestFieldValues: fieldChart.weatherData && fieldChart.weatherData.omdGoal && fieldChart.weatherData.omdGoal.fields
      });
    }
    this.setState({
      omdErrorMessage: false
    });

    let data = [];
    let keyPair = {};
    fieldList.map(field => (
      //eslint-disable-next-line
      keyPair = {},
      keyPair['id'] = field.fieldId,
      keyPair['show'] = field.fieldId === (selectedFieldId !== null ? selectedFieldId : paramId) ? true : false,
      data.push(keyPair)
    ));
    this.setState({
      selectedFieldCheckbox: data
    });
    let checkFieldSelected = _.filter(data, ['show', false]);
    if(checkFieldSelected.length > 0) {
      selectBox = false;
    }
    this.setState({
      omdGraphValue: null,
      selectAllCheckbox: selectBox
    });
    let omdValue = null;
    if(fieldChart.omdData !== null) {
      omdValue = fieldChart.omdData && fieldChart.omdData.value;
    } else {
      omdValue = fieldChart.weatherData && fieldChart.weatherData.omdGoal && fieldChart.weatherData.omdGoal.value;
    }
    this.setState({
      omdGraphValue: omdValue,
      openOmdBox: true
    });
  }

  closeOmdDialogBox = () => {
    this.setState({
      openOmdBox: false,
      omdGraphValue: null,
      omdErrorMessage: false
    });
  }

  checkAllFields = () => {
    const { selectedFieldCheckbox, selectAllCheckbox, paramId, selectedFieldId } = this.state;
    let selectBox = true;
    if(selectAllCheckbox) {
      selectBox = false;
    }
    let data = [];
    let keyPair = {};
    selectedFieldCheckbox.map(field => (
      //eslint-disable-next-line
      keyPair = {},
      keyPair['id'] = field.id,
      keyPair['show'] = field.id === (selectedFieldId !== null ? selectedFieldId : paramId) ? true : (selectAllCheckbox ? false : true),
      data.push(keyPair)
    ))
    this.setState({
      selectedFieldCheckbox: data,
      selectAllCheckbox: selectBox
    });
  }

  handleSubmitModel = () => {
    const { updateOmdValue, activeFarm } = this.props;
    const { omdGraphValue, selectedFieldCheckbox, paramId, selectedFieldId } = this.state;
    this.setState({
      omdErrorMessage: false
    })
    let fieldIds = null;
    const selectedFields = _.filter(selectedFieldCheckbox, ['show', true]);
    if(selectedFields.length > 0) {
      fieldIds = [];
      selectedFields.map(field => (
        fieldIds.push(field.id)
      ))
    }
    if(fieldIds === null || omdGraphValue === null) {
      this.setState({
        omdErrorMessage: true
      });
    } else {
      let formDataObject = {"fieldIds": fieldIds, "value": parseInt(omdGraphValue) };
      updateOmdValue(activeFarm.farm.id, (selectedFieldId !== null ? selectedFieldId : paramId), formDataObject);
      this.setState({
        openOmdBox: false,
        omdGraphValue: null,
        omdErrorMessage: false
      });
    }
  }

  handleOmdChange = (event) => {
    this.setState({
      omdGraphValue: event.target.value
    });
  };

  checkBoxChange = e => {
    const { selectedFieldCheckbox, paramId, selectedFieldId } = this.state;
    let data = [];
    let keyPair = {};
    selectedFieldCheckbox.map(field => (
      //eslint-disable-next-line
      keyPair = {},
      keyPair['id'] = field.id,
      keyPair['show'] = e === (selectedFieldId !== null ? selectedFieldId : paramId) ? true : e === field.id ? (field.show === true ? false : true) : field.show,
      data.push(keyPair)
    ));
    this.setState({
      selectedFieldCheckbox: data
    });
  }

  changeField = (fieldId) => {
    const { history, fetchWeatherData, activeFarm } = this.props;
    this.setState({
      series: null,
      chart2Series: null,
      options: null,
      chart2Options: null,
      displayChart: false,
      chart2Display: false,
      chartYearChecked: new Map(),
      chartGraphChecked: new Map(),
      tempSumLegendColor: new Map(),
      meanTempLegendColor: new Map(),
      dailyRainfallLegendColor: new Map(),
      raProteinLegendColor: new Map(),
      omdLegendColor: new Map(),
      customColors: null,
      loadingChart: false,
      strokeDashArray: null,
      discreteArray: null,
      markerSizeArray: null,
      yAxisSeries: null,
      chart2CustomColors: null,
      chart2LoadingChart: false,
      chart2StrokeDashArray: null,
      chart2DiscreteArray: null,
      chart2MarkerSizeArray: null,
      chart2yAxisSeries: null,
      chart2xAxisSeries: null,
      onChangeStation: false,
      temperatureSumColor: TEMPERATURE_SUM_COLOR_LIST,
      meanTemperatureColor: MEAN_TEMPERATURE_COLOR_LIST,
      dailyRainfallColor: DAILY_RAINFALL_COLOR_LIST,
      raProteinColor: RAPROTEIN_COLOR_LIST,
      omdColor: OMD_COLOR_LIST,
      selectedFieldId: fieldId
    });
    const formDataObject = { "fieldId": fieldId, "celciusDaysTarget": 475 };
    this.props.fieldChart.weatherData = null;
    fetchWeatherData(activeFarm.farm.id, formDataObject);
    history.push(`/managefields/${fieldId}/weather`);
  }

  /** Update the chart based on year selection */
  updateChartSeries = () => {
    const { fieldChart } = this.props;
    if(fieldChart && fieldChart.weatherData !== null && fieldChart.weatherData.temperatureSumChart !== null && 
        fieldChart.weatherData.meanTemperatureChart !== null && fieldChart.weatherData.dailyRainfallChart !== null) {
      
      /** TemperatureSum graph forecast data */
      let temperatureSumForecastList = {};
      let mergedArray = [];
      let temperatureSumLength = fieldChart.weatherData.temperatureSumChart.series.length;
      if(fieldChart.weatherData.forecastPoints > 0) {
        temperatureSumForecastList['name'] = fieldChart.weatherData.temperatureSumChart.series[1].name.toString();
        temperatureSumForecastList['data'] = fieldChart.weatherData.temperatureSumChart.series[0].data;
        if(fieldChart.weatherData.temperatureSumChart.series[0].harvestTempSum && fieldChart.weatherData.temperatureSumChart.series[0].harvestTempSum.length > 0) {
          mergedArray = fieldChart.weatherData.temperatureSumChart.series[1].harvestTempSum.map((item, index) => { return item !== null ? item : (
            fieldChart.weatherData.temperatureSumChart.series[0].harvestTempSum[index]
          )});
        } else {
          mergedArray = fieldChart.weatherData.temperatureSumChart.series[1].harvestTempSum;
        }
        temperatureSumForecastList['harvestTempSum'] = mergedArray;
        temperatureSumForecastList['graphType'] = 'graph_0';
      } else {
        temperatureSumForecastList = null;
      }

      /** MeanTemperature graph forecast data */
      let meanTemperatureForecastList = {};
      let meanTemperatureSumLength = fieldChart.weatherData.meanTemperatureChart.series.length;
      if(fieldChart.weatherData.forecastPoints > 0) {
        meanTemperatureForecastList['name'] = fieldChart.weatherData.meanTemperatureChart.series[1].name.toString();
        meanTemperatureForecastList['data'] = fieldChart.weatherData.meanTemperatureChart.series[0].data;
      } else {
        meanTemperatureForecastList = null;
      }

      /** RainfallChart graph forecast data */
      let dailyRainfallChartForecastList = {};
      let dailyRainfallChartSumLength = fieldChart.weatherData.dailyRainfallChart.series.length;
      if(fieldChart.weatherData.forecastPoints > 0) {
        dailyRainfallChartForecastList['name'] = fieldChart.weatherData.dailyRainfallChart.series[1].name.toString();
        dailyRainfallChartForecastList['data'] = fieldChart.weatherData.dailyRainfallChart.series[0].data;
      } else {
        dailyRainfallChartForecastList = null;
      }

      /** Raprotein graph forecast data */
      let raproteinForecastList = {};      
      let raproteinLength = fieldChart.weatherData.rawProteinChart.series.length;
      if(fieldChart.weatherData.forecastPoints > 0) {
        raproteinForecastList['name'] = fieldChart.weatherData.rawProteinChart.series[1].name.toString();
        raproteinForecastList['data'] = fieldChart.weatherData.rawProteinChart.series[0].data;
      } else {
        raproteinForecastList = null;
      }

      /** OMD graph forecast data */
      let omdForecastList = {};      
      let omdLength = fieldChart.weatherData.omdChart.series.length;
      if(fieldChart.weatherData.forecastPoints > 0) {
        omdForecastList['name'] = fieldChart.weatherData.omdChart.series[1].name.toString();
        omdForecastList['data'] = fieldChart.weatherData.omdChart.series[0].data;
      } else {
        omdForecastList = null;
      }

      this.updateChartTemperatureSumSeries(temperatureSumLength, meanTemperatureSumLength, dailyRainfallChartSumLength, omdLength, raproteinLength, 
        temperatureSumForecastList, meanTemperatureForecastList, dailyRainfallChartForecastList, omdForecastList, raproteinForecastList, fieldChart);
    }
  }
 
  /** Update the chart based on temperature */
  updateChartTemperatureSumSeries = (temperatureSumLength, meanTemperatureSumLength, dailyRainfallChartSumLength, omdLength, raproteinLength, 
    temperatureSumForecastList, meanTemperatureForecastList, dailyRainfallChartForecastList, omdForecastList, raproteinForecastList, fieldChart) => {
    const { chartYearChecked, chartGraphChecked, temperatureSumColor, meanTemperatureColor, dailyRainfallColor, raProteinColor, omdColor } = this.state;
    let seriesList = {};
    let seriesArray = [];
    let customColorsArray = [];
    let dashArray = [];
    let markerSize = [];
    let discreteValuesLength = 0;
    let discreteOmdValuesLength = 0;
    let discreteValues = [];
    let discreteOmdValues = [];
    let yAxisTempSumArray = [];
    let yAxisMeanTempArray = [];
    let yAxisDailyRainfallArray = [];
    let yAxisOmdArray = [];
    let yAxisRaproteinArray = [];
    let tempSumLegendColorArray = new Map();
    let meanTempLegendColorArray = new Map();
    let dailyRainfallLegendColorArray = new Map();
    let omdLegendColorArray = new Map();
    let raproteinLegendColorArray = new Map();
    let yAxisTempSumInitial = {
      min: TEMPERATURE_SUM_MIN_VALUE,
      max: TEMPERATURE_SUM_MAX_VALUE,
      title: {
        text: fieldChart.weatherData.temperatureSumChart.yaxis.title.text,
        style: TEMPERATURE_TITLE_STYLE
      },
      tickAmount: YAXIS_TICK_AMOUNT,
      forceNiceScale: true
    };
    let yAxisRaproteinInitial = {
      min: TEMPERATURE_SUM_MIN_VALUE,
      max: TEMPERATURE_SUM_MAX_VALUE,
      title: {
        text: fieldChart.weatherData.rawProteinChart.yaxis.title.text,
        style: TEMPERATURE_TITLE_STYLE
      },
      tickAmount: YAXIS_TICK_AMOUNT,
      forceNiceScale: true
    };
    let yAxisRaproteinInitial1 = {
      min: TEMPERATURE_SUM_MIN_VALUE,
      max: TEMPERATURE_SUM_MAX_VALUE,
      title: {
        text: undefined,
        style: TEMPERATURE_TITLE_STYLE
      },
      labels: {
        enabled: false,
        show: false
      },
      tickAmount: YAXIS_TICK_AMOUNT,
      forceNiceScale: true
    };
    let yAxisRaproteinInitial2 = {
      min: TEMPERATURE_SUM_MIN_VALUE,
      max: TEMPERATURE_SUM_MAX_VALUE,
      title: {
        text: `${fieldChart.weatherData.temperatureSumChart.yaxis.title.text} / ${fieldChart.weatherData.rawProteinChart.yaxis.title.text}`,
        style: TEMPERATURE_TITLE_STYLE
      },
      labels: {
        enabled: false
      },
      tickAmount: YAXIS_TICK_AMOUNT,
      forceNiceScale: true
    };
    let yAxisMeanTempInitial = {
      min: MEAN_TEMPERATURE_MIN_VALUE,
      max: MEAN_TEMPERATURE_MAX_VALUE,
      title: {
        text: fieldChart.weatherData.meanTemperatureChart.yaxis.title.text,
        style: TEMPERATURE_TITLE_STYLE
      },
      opposite: true,
      tickAmount: YAXIS_TICK_AMOUNT,
      forceNiceScale: true
    };
    let yAxisDailyRainfallInitial = {
      min: MEAN_TEMPERATURE_MIN_VALUE,
      max: MEAN_TEMPERATURE_MAX_VALUE,
      title: {
        text: fieldChart.weatherData.dailyRainfallChart.yaxis.title.text,
        style: TEMPERATURE_TITLE_STYLE
      },
      opposite: true,
      tickAmount: YAXIS_TICK_AMOUNT,
      forceNiceScale: true
    };
    let yAxisOmdInitial = {
      min: MEAN_TEMPERATURE_MIN_VALUE,
      max: MEAN_TEMPERATURE_MAX_VALUE,
      title: {
        text: fieldChart.weatherData.omdChart.yaxis.title.text,
        style: TEMPERATURE_TITLE_STYLE
      },
      opposite: true,
      tickAmount: YAXIS_TICK_AMOUNT,
      forceNiceScale: true
    };
    let yAxisMeanTempDailyRainfallInitial1 = {
      min: MEAN_TEMPERATURE_MIN_VALUE,
      max: MEAN_TEMPERATURE_MAX_VALUE,
      title: {
        text: `${fieldChart.weatherData.meanTemperatureChart.yaxis.title.text} / ${fieldChart.weatherData.dailyRainfallChart.yaxis.title.text} / ${fieldChart.weatherData.omdChart.yaxis.title.text}`,
        style: TEMPERATURE_TITLE_STYLE
      },
      opposite: true,
      labels: {
        enabled: false
      },
      tickAmount: YAXIS_TICK_AMOUNT,
      forceNiceScale: true
    };
    let yAxisMeanTempDailyRainfallInitial2 = {
      min: MEAN_TEMPERATURE_MIN_VALUE,
      max: MEAN_TEMPERATURE_MAX_VALUE,
      title: {
        text: `${fieldChart.weatherData.meanTemperatureChart.yaxis.title.text} / ${fieldChart.weatherData.dailyRainfallChart.yaxis.title.text}`,
        style: TEMPERATURE_TITLE_STYLE
      },
      opposite: true,
      labels: {
        enabled: false
      },
      tickAmount: YAXIS_TICK_AMOUNT,
      forceNiceScale: true
    };
    let yAxisMeanTempDailyRainfallInitial3 = {
      min: MEAN_TEMPERATURE_MIN_VALUE,
      max: MEAN_TEMPERATURE_MAX_VALUE,
      title: {
        text: `${fieldChart.weatherData.meanTemperatureChart.yaxis.title.text} / ${fieldChart.weatherData.omdChart.yaxis.title.text}`,
        style: TEMPERATURE_TITLE_STYLE
      },
      opposite: true,
      labels: {
        enabled: false
      },
      tickAmount: YAXIS_TICK_AMOUNT,
      forceNiceScale: true
    };
    let yAxisMeanTempDailyRainfallInitial4= {
      min: MEAN_TEMPERATURE_MIN_VALUE,
      max: MEAN_TEMPERATURE_MAX_VALUE,
      title: {
        text: `${fieldChart.weatherData.dailyRainfallChart.yaxis.title.text} / ${fieldChart.weatherData.omdChart.yaxis.title.text}`,
        style: TEMPERATURE_TITLE_STYLE
      },
      opposite: true,
      labels: {
        enabled: false
      },
      tickAmount: YAXIS_TICK_AMOUNT,
      forceNiceScale: true
    };

    let yAxisMeanTempDailyRainfallUndefined = {
      min: MEAN_TEMPERATURE_MIN_VALUE,
      max: MEAN_TEMPERATURE_MAX_VALUE,
      title: {
        text: undefined,
        style: TEMPERATURE_TITLE_STYLE
      },
      opposite: true,
      labels: {
        enabled: false,
        show: false
      },
      tickAmount: YAXIS_TICK_AMOUNT,
      forceNiceScale: true
    };
    let seriesDiscreate = 0;
    /** If temperatureSum is selected */
    if(chartGraphChecked.get("graph_0")) {
      let tempSumLength = fieldChart.weatherData.temperatureSumChart.series.length;
      let mergedArray = [];
      fieldChart.weatherData.temperatureSumChart.series.slice(0).reverse().map((series, index) => (
        //eslint-disable-next-line
        discreteValuesLength = series.harvestDateIndexes ? series.harvestDateIndexes.length : 0,
        discreteOmdValuesLength = series.omdIndexMaps ? series.omdIndexMaps.length : 0,
        seriesList = {},
        seriesList['name'] = series.name.toString(),
        seriesList['data'] = series.data,
        seriesList['yAxis'] = 1,
        seriesList['tooltip'] = ' °C',
        seriesList['graphType'] = 'graph_0',
        temperatureSumForecastList !== null && index === tempSumLength-2 ?
        (
          (fieldChart.weatherData.temperatureSumChart.series.slice(0).reverse()[tempSumLength-1].harvestTempSum && 
          fieldChart.weatherData.temperatureSumChart.series.slice(0).reverse()[tempSumLength-1].harvestTempSum.length > 0) ?
          (mergedArray = series.harvestTempSum.map((item, indexx) => { return item !== null ? item : (
            fieldChart.weatherData.temperatureSumChart.series.slice(0).reverse()[tempSumLength-1].harvestTempSum[indexx]
          )})) :
          (mergedArray = series.harvestTempSum)
        ) : (mergedArray = series.harvestTempSum),

        /*temperatureSumForecastList !== null ?
        (
          (series.harvestTempSum && series.harvestTempSum.length > 0) ?
          (mergedArray = fieldChart.weatherData.temperatureSumChart.series.slice(0).reverse()[tempSumLength-2].harvestTempSum.map((item, indexx) => { return item !== null ? item : (
            series.harvestTempSum[indexx]
          )})) :
          (mergedArray = series.harvestTempSum)
        ) : (mergedArray = series.harvestTempSum),*/
        seriesList['harvestTempSum'] = mergedArray,

        /** Pushing the data to series based on checkbox selection */
        (chartYearChecked.get(series.name.toString()) ? seriesArray.push(seriesList) : ''),
        temperatureSumForecastList !== null ?
        ((index === temperatureSumLength-2 && chartYearChecked.get(series.name.toString())) ? seriesArray.push(temperatureSumForecastList) : '') : '',

        /** Display the custom color */
        chartYearChecked.get(series.name.toString()) ? customColorsArray.push(temperatureSumColor[index]) : '',
        temperatureSumForecastList !== null ?
        ((index === temperatureSumLength-2 && chartYearChecked.get(series.name.toString())) ? customColorsArray.push(temperatureSumColor[index]) : '') : '',

        /** Assign color for legend */
        chartYearChecked.get(series.name.toString()) ? tempSumLegendColorArray.set(series.name.toString(),temperatureSumColor[index]) : '',

        /** Display the stroke for forecast data */
        chartYearChecked.get(series.name.toString()) ? dashArray.push(0) : '',
        temperatureSumForecastList !== null ?
        ((index === temperatureSumLength-2 && chartYearChecked.get(series.name.toString())) ? dashArray.push(3) : '') : '',

        /** Display the markers size */
        chartYearChecked.get(series.name.toString()) ? markerSize.push(1) : '',
        temperatureSumForecastList !== null ?
        ((index === temperatureSumLength-2 && chartYearChecked.get(series.name.toString())) ? markerSize.push(1) : '') : '',

        /** Assign y-axis based on series */
        chartGraphChecked.get("graph_0") && chartGraphChecked.get("graph_3") ?
        chartYearChecked.get(series.name.toString()) ? yAxisTempSumArray.length === 0 ? yAxisTempSumArray.push(yAxisRaproteinInitial2) : yAxisTempSumArray.push(TEMPERATURE_SUM_YAXIS_DEFAULT) : '' :
        chartYearChecked.get(series.name.toString()) ? yAxisTempSumArray.length === 0 ? yAxisTempSumArray.push(yAxisTempSumInitial) : yAxisTempSumArray.push(TEMPERATURE_SUM_YAXIS_DEFAULT) : '' ,

        temperatureSumForecastList !== null ?
        ((index === temperatureSumLength-2 && chartYearChecked.get(series.name.toString())) ? yAxisTempSumArray.push(TEMPERATURE_SUM_YAXIS_DEFAULT) : '') : '',
        series.harvestDateIndexes && discreteValuesLength > 0 ? 
        chartYearChecked.get(series.name.toString()) ?
        series.harvestDateIndexes.map(harvestDate => (       
          harvestDate !== -1 ? 
          discreteValues.push(
          {
            seriesIndex: seriesDiscreate,
            dataPointIndex: harvestDate,
            size: 5,
            fillColor: DISCRETE_COLOR,
            strokeColor: DISCRETE_COLOR,
            shape: "circle",
          }) : ''
        )) : '' : '',
        series.omdIndexMaps && discreteOmdValuesLength > 0 ? 
        (chartYearChecked.get(series.name.toString()) && series.name.toString() === 'tempSumForecast') ?
        series.omdIndexMaps.map(omdIndexMapList => (
          Object.keys(omdIndexMapList).map(omdValue => (
            omdValue !== -1 ? 
            discreteOmdValues.push({
              seriesIndex: seriesDiscreate,
              dataPointIndex: parseInt(omdValue),
              size: 3,
              fillColor: DISCRETE_OMD_COLOR,
              strokeColor: DISCRETE_OMD_COLOR,
              shape: "circle",
              dataValue: omdIndexMapList[omdValue]
            }) : ''
          ))
         )) : '' : '',
        series.omdIndexMaps && discreteOmdValuesLength > 0 ? 
        chartYearChecked.get(series.name.toString()) ?
        series.omdIndexMaps.map(omdIndexMapList => (
          Object.keys(omdIndexMapList).map(omdValue => (
            omdValue !== -1 ? 
            discreteOmdValues.push({
              seriesIndex: seriesDiscreate,
              dataPointIndex: parseInt(omdValue),
              size: 3,
              fillColor: DISCRETE_OMD_COLOR,
              strokeColor: DISCRETE_OMD_COLOR,
              shape: "circle",
              dataValue: omdIndexMapList[omdValue]
            }) : ''
          ))
         )) : '' : '',
        chartYearChecked.get(series.name.toString()) ? seriesDiscreate++ : ''
      ));
    }

    /*if(chartGraphChecked.get("graph_0")) {
      if(fieldChart.weatherData.temperatureSumChart.series[0].name === "tempSumForecast") {
        if(fieldChart.weatherData.temperatureSumChart.series[0].omdIndexMaps && 
          fieldChart.weatherData.temperatureSumChart.series[0].omdIndexMaps.length > 0) {
          fieldChart.weatherData.temperatureSumChart.series[0].omdIndexMaps.map(omdIndexMapList => (
            Object.keys(omdIndexMapList).length > 0 ?
              chartYearChecked.get(fieldChart.weatherData.temperatureSumChart.series[0].name.toString()) ?
              (Object.keys(omdIndexMapList).map(omdValue => (
                omdValue !== -1 ? 
                discreteOmdValues.push({
                  seriesIndex: seriesDiscreate,
                  dataPointIndex: parseInt(omdValue),
                  size: 3,
                  fillColor: DISCRETE_OMD_COLOR,
                  strokeColor: DISCRETE_OMD_COLOR,
                  shape: "circle",
                  dataValue: omdIndexMapList[omdValue]
                }) : ''
              ))) : '' : ''
          ));
        }
      }
    }*/

    /** If meanTemperature is selected */
    if(chartGraphChecked.get("graph_1")) {
      fieldChart.weatherData.meanTemperatureChart.series.slice(0).reverse().map((series, index) => (
        //eslint-disable-next-line
        seriesList = {},
        seriesList['name'] = series.name.toString(),
        seriesList['data'] = series.data,
        seriesList['yAxis'] = 1,
        seriesList['tooltip'] = ' °C',
        seriesList['graphType'] = 'graph_1',

        /** Pushing the data to series based on checkbox selection */
        chartYearChecked.get(series.name.toString()) ? seriesArray.push(seriesList) : '',
        meanTemperatureForecastList !== null ?
        ((index === meanTemperatureSumLength-2 && chartYearChecked.get(series.name.toString())) ? seriesArray.push(meanTemperatureForecastList) : '') : '',

        /** Display the custom color */
        chartYearChecked.get(series.name.toString()) ? customColorsArray.push(meanTemperatureColor[index]) : '',
        meanTemperatureForecastList !== null ?
        ((index === meanTemperatureSumLength-2 && chartYearChecked.get(series.name.toString())) ? customColorsArray.push(meanTemperatureColor[index]) : '') : '',

        /** Assign color for legend */
        chartYearChecked.get(series.name.toString()) ? meanTempLegendColorArray.set(series.name.toString(),meanTemperatureColor[index]) : '',

        /** Display the stroke for forecast data */
        chartYearChecked.get(series.name.toString()) ? dashArray.push(0) : '',
        meanTemperatureForecastList !== null ?
        ((index === meanTemperatureSumLength-2 && chartYearChecked.get(series.name.toString())) ? dashArray.push(3) : '') : '',

        /** Display the markers size */
        chartYearChecked.get(series.name.toString()) ? markerSize.push(1) : '',
        meanTemperatureForecastList !== null ?
        ((index === meanTemperatureSumLength-2 && chartYearChecked.get(series.name.toString())) ? markerSize.push(0) : '') : '',

        /** Assign y-axis based on series */
        chartGraphChecked.get("graph_1") && chartGraphChecked.get("graph_2") && chartGraphChecked.get("graph_4") ?
        (chartYearChecked.get(series.name.toString()) ? (yAxisMeanTempArray.length === 0 ? yAxisMeanTempArray.push(yAxisMeanTempDailyRainfallInitial1) : yAxisMeanTempArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT)) : '') :
        chartGraphChecked.get("graph_1") && chartGraphChecked.get("graph_2") ?
        (chartYearChecked.get(series.name.toString()) ? (yAxisMeanTempArray.length === 0 ? yAxisMeanTempArray.push(yAxisMeanTempDailyRainfallInitial2) : yAxisMeanTempArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT)) : '') :
        chartGraphChecked.get("graph_1") && chartGraphChecked.get("graph_4") ?
        (chartYearChecked.get(series.name.toString()) ? (yAxisMeanTempArray.length === 0 ? yAxisMeanTempArray.push(yAxisMeanTempDailyRainfallInitial3) : yAxisMeanTempArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT)) : '') :
        chartGraphChecked.get("graph_2") && chartGraphChecked.get("graph_4") ?
        (chartYearChecked.get(series.name.toString()) ? (yAxisMeanTempArray.length === 0 ? yAxisMeanTempArray.push(yAxisMeanTempDailyRainfallInitial4) : yAxisMeanTempArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT)) : '') :
        chartYearChecked.get(series.name.toString()) ? (yAxisMeanTempArray.length === 0 ? yAxisMeanTempArray.push(yAxisMeanTempInitial) : yAxisMeanTempArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT)) : '' ,
        
        meanTemperatureForecastList !== null ?
        ((index === meanTemperatureSumLength-2 && chartYearChecked.get(series.name.toString())) ? yAxisMeanTempArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT) : '') : ''
      ));
    }

    /** If Rainfall is selected */
    if(chartGraphChecked.get("graph_2")) {
      fieldChart.weatherData.dailyRainfallChart.series.slice(0).reverse().map((series, index) => (
        //eslint-disable-next-line
        seriesList = {},
        seriesList['name'] = series.name.toString(),
        seriesList['data'] = series.data,
        seriesList['yAxis'] = 1,
        seriesList['tooltip'] = ' mm',
        seriesList['graphType'] = 'graph_2',

        /** Pushing the data to series based on checkbox selection */
        chartYearChecked.get(series.name.toString()) ? seriesArray.push(seriesList) : '',
        dailyRainfallChartForecastList !== null ?
        ((index === dailyRainfallChartSumLength-2 && chartYearChecked.get(series.name.toString())) ? seriesArray.push(dailyRainfallChartForecastList) : '') : '',

        /** Display the custom color */
        chartYearChecked.get(series.name.toString()) ? customColorsArray.push(dailyRainfallColor[index]) : '',
        dailyRainfallChartForecastList !== null ?
        ((index === dailyRainfallChartSumLength-2 && chartYearChecked.get(series.name.toString())) ? customColorsArray.push(dailyRainfallColor[index]) : '') : '',

        /** Assign color for legend */
        chartYearChecked.get(series.name.toString()) ? dailyRainfallLegendColorArray.set(series.name.toString(),dailyRainfallColor[index]) : '',

        /** Display the stroke for forecast data */
        chartYearChecked.get(series.name.toString()) ? dashArray.push(0) : '',
        dailyRainfallChartForecastList !== null ?
        ((index === dailyRainfallChartSumLength-2 && chartYearChecked.get(series.name.toString())) ? dashArray.push(3) : '') : '',

        /** Display the markers size */
        chartYearChecked.get(series.name.toString()) ? markerSize.push(1) : '',
        dailyRainfallChartForecastList !== null ?
        ((index === dailyRainfallChartSumLength-2 && chartYearChecked.get(series.name.toString())) ? markerSize.push(0) : '') : '',

        /** Assign y-axis based on series */
        chartGraphChecked.get("graph_1") && chartGraphChecked.get("graph_2") && chartGraphChecked.get("graph_4") ?
        chartYearChecked.get(series.name.toString()) ? yAxisDailyRainfallArray.length === 0 ? yAxisDailyRainfallArray.push(yAxisMeanTempDailyRainfallUndefined) : yAxisDailyRainfallArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT) : '' :
        chartGraphChecked.get("graph_1") && chartGraphChecked.get("graph_2") ?
        chartYearChecked.get(series.name.toString()) ? yAxisDailyRainfallArray.length === 0 ? yAxisDailyRainfallArray.push(yAxisMeanTempDailyRainfallUndefined) : yAxisDailyRainfallArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT) : '' :
        chartGraphChecked.get("graph_1") && chartGraphChecked.get("graph_4") ?
        chartYearChecked.get(series.name.toString()) ? yAxisDailyRainfallArray.length === 0 ? yAxisDailyRainfallArray.push(yAxisMeanTempDailyRainfallUndefined) : yAxisDailyRainfallArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT) : '' :
        chartGraphChecked.get("graph_2") && chartGraphChecked.get("graph_4") ?
        chartYearChecked.get(series.name.toString()) ? yAxisDailyRainfallArray.length === 0 ? yAxisDailyRainfallArray.push(yAxisMeanTempDailyRainfallInitial4) : yAxisDailyRainfallArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT) : '' :
        chartYearChecked.get(series.name.toString()) ? yAxisDailyRainfallArray.length === 0 ? yAxisDailyRainfallArray.push(yAxisDailyRainfallInitial) : yAxisDailyRainfallArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT) : '' ,
        
        /*chartGraphChecked.get("graph_1") && chartGraphChecked.get("graph_2") ? '' :
        chartYearChecked.get(series.name.toString()) ? yAxisDailyRainfallArray.length === 0 ? yAxisDailyRainfallArray.push(yAxisDailyRainfallInitial) : yAxisDailyRainfallArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT) : '',
        */dailyRainfallChartForecastList !== null ?
        ((index === dailyRainfallChartSumLength-2 && chartYearChecked.get(series.name.toString())) ? yAxisDailyRainfallArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT2) : '') : ''
      ));
    }

    /** If Raprotein is selected */
    if(chartGraphChecked.get("graph_3")) {
      fieldChart.weatherData.rawProteinChart.series.slice(0).reverse().map((series, index) => (
        //eslint-disable-next-line
        seriesList = {},
        seriesList['name'] = series.name.toString(),
        seriesList['data'] = series.data,
        seriesList['yAxis'] = 1,
        seriesList['tooltip'] = ' g/kg TS',
        seriesList['graphType'] = 'graph_3',

        /** Pushing the data to series based on checkbox selection */
        chartYearChecked.get(series.name.toString()) ? seriesArray.push(seriesList) : '',
        raproteinForecastList !== null ?
        ((index === raproteinLength-2 && chartYearChecked.get(series.name.toString())) ? seriesArray.push(raproteinForecastList) : '') : '',

        /** Display the custom color */
        chartYearChecked.get(series.name.toString()) ? customColorsArray.push(raProteinColor[index]) : '',
        raproteinForecastList !== null ?
        ((index === raproteinLength-2 && chartYearChecked.get(series.name.toString())) ? customColorsArray.push(raProteinColor[index]) : '') : '',

        /** Assign color for legend */
        chartYearChecked.get(series.name.toString()) ? raproteinLegendColorArray.set(series.name.toString(),raProteinColor[index]) : '',

        /** Display the stroke for forecast data */
        chartYearChecked.get(series.name.toString()) ? dashArray.push(0) : '',
        raproteinForecastList !== null ?
        ((index === raproteinLength-2 && chartYearChecked.get(series.name.toString())) ? dashArray.push(3) : '') : '',

        /** Display the markers size */
        chartYearChecked.get(series.name.toString()) ? markerSize.push(5) : '',
        raproteinForecastList !== null ?
        ((index === raproteinLength-2 && chartYearChecked.get(series.name.toString())) ? markerSize.push(0) : '') : '',

        /** Assign y-axis based on series */
        chartGraphChecked.get("graph_0") && chartGraphChecked.get("graph_3") ?
        chartYearChecked.get(series.name.toString()) ? yAxisRaproteinArray.length === 0 ? yAxisRaproteinArray.push(yAxisRaproteinInitial1) : yAxisRaproteinArray.push(TEMPERATURE_SUM_YAXIS_DEFAULT) : '' :
        chartYearChecked.get(series.name.toString()) ? yAxisRaproteinArray.length === 0 ? yAxisRaproteinArray.push(yAxisRaproteinInitial) : yAxisRaproteinArray.push(TEMPERATURE_SUM_YAXIS_DEFAULT) : '' ,
        
        /*chartGraphChecked.get("graph_1") && chartGraphChecked.get("graph_2") ? '' :
        chartYearChecked.get(series.name.toString()) ? yAxisRaproteinArray.length === 0 ? yAxisRaproteinArray.push(yAxisRaproteinInitial) : yAxisRaproteinArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT) : '',
        */raproteinForecastList !== null ?
        ((index === raproteinLength-2 && chartYearChecked.get(series.name.toString())) ? yAxisRaproteinArray.push(TEMPERATURE_SUM_YAXIS_DEFAULT) : '') : ''
      ));
    }

    /** If OMD is selected */
    if(chartGraphChecked.get("graph_4")) {
      fieldChart.weatherData.omdChart.series.slice(0).reverse().map((series, index) => (
        //eslint-disable-next-line
        seriesList = {},
        seriesList['name'] = series.name.toString(),
        seriesList['data'] = series.data,
        seriesList['yAxis'] = 1,
        seriesList['tooltip'] = ' % OMD',
        seriesList['graphType'] = 'graph_4',

        /** Pushing the data to series based on checkbox selection */
        chartYearChecked.get(series.name.toString()) ? seriesArray.push(seriesList) : '',
        omdForecastList !== null ?
        ((index === omdLength-2 && chartYearChecked.get(series.name.toString())) ? seriesArray.push(omdForecastList) : '') : '',

        /** Display the custom color */
        chartYearChecked.get(series.name.toString()) ? customColorsArray.push(omdColor[index]) : '',
        omdForecastList !== null ?
        ((index === omdLength-2 && chartYearChecked.get(series.name.toString())) ? customColorsArray.push(omdColor[index]) : '') : '',

        /** Assign color for legend */
        chartYearChecked.get(series.name.toString()) ? omdLegendColorArray.set(series.name.toString(),omdColor[index]) : '',

        /** Display the stroke for forecast data */
        chartYearChecked.get(series.name.toString()) ? dashArray.push(0) : '',
        omdForecastList !== null ?
        ((index === omdLength-2 && chartYearChecked.get(series.name.toString())) ? dashArray.push(3) : '') : '',

        /** Display the markers size */
        chartYearChecked.get(series.name.toString()) ? markerSize.push(5) : '',
        omdForecastList !== null ?
        ((index === omdLength-2 && chartYearChecked.get(series.name.toString())) ? markerSize.push(0) : '') : '',

        /** Assign y-axis based on series */
        chartGraphChecked.get("graph_1") && chartGraphChecked.get("graph_2") && chartGraphChecked.get("graph_4") ?
        chartYearChecked.get(series.name.toString()) ? yAxisOmdArray.length === 0 ? yAxisOmdArray.push(yAxisMeanTempDailyRainfallUndefined) : yAxisOmdArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT) : '' :
        chartGraphChecked.get("graph_1") && chartGraphChecked.get("graph_2") ?
        chartYearChecked.get(series.name.toString()) ? yAxisOmdArray.length === 0 ? yAxisOmdArray.push(yAxisMeanTempDailyRainfallUndefined) : yAxisOmdArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT) : '' :
        chartGraphChecked.get("graph_1") && chartGraphChecked.get("graph_4") ?
        chartYearChecked.get(series.name.toString()) ? yAxisOmdArray.length === 0 ? yAxisOmdArray.push(yAxisMeanTempDailyRainfallUndefined) : yAxisOmdArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT) : '' :
        chartGraphChecked.get("graph_2") && chartGraphChecked.get("graph_4") ?
        chartYearChecked.get(series.name.toString()) ? yAxisOmdArray.length === 0 ? yAxisOmdArray.push(yAxisMeanTempDailyRainfallUndefined) : yAxisOmdArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT) : '' :
        chartYearChecked.get(series.name.toString()) ? yAxisOmdArray.length === 0 ? yAxisOmdArray.push(yAxisOmdInitial) : yAxisOmdArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT) : '' ,
        
        /*chartGraphChecked.get("graph_1") && chartGraphChecked.get("graph_2") ? '' :
        chartYearChecked.get(series.name.toString()) ? yAxisRaproteinArray.length === 0 ? yAxisRaproteinArray.push(yAxisRaproteinInitial) : yAxisRaproteinArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT) : '',
        */omdForecastList !== null ?
        ((index === raproteinLength-2 && chartYearChecked.get(series.name.toString())) ? yAxisRaproteinArray.push(MEAN_TEMPERATURE_YAXIS_DEFAULT2) : '') : ''
      ));
    }
    this.setState({
      loadingChart: false,
      series: seriesArray,
      customColors: customColorsArray,
      strokeDashArray: dashArray,
      markerSizeArray: markerSize,
      discreteArray: discreteValues,
      discreteOmdArray: discreteOmdValues,
      tempSumLegendColor: tempSumLegendColorArray,
      meanTempLegendColor: meanTempLegendColorArray,
      dailyRainfallLegendColor: dailyRainfallLegendColorArray,
      raproteinLegendColor:raproteinLegendColorArray,
      omdLegendColor: omdLegendColorArray,
      yAxisSeries: [...yAxisTempSumArray, ...yAxisMeanTempArray, ...yAxisDailyRainfallArray, ...yAxisRaproteinArray, ...yAxisOmdArray]
    });
  }

  /** Changes when the chart year has been selected */
  chartYearChange = (e) => {
    const { fieldChart } = this.props;
    const { chartYearChecked } = this.state;
    fieldChart.weatherData.years.map((year) => (
      this.setState({ chartYearChecked: chartYearChecked.set(year, false) })
    ));
    e.target.value && e.target.value.map(year => (
      this.setState({ chartYearChecked: chartYearChecked.set(year, true) })
    ));
    this.setState({
      selectedYearValues: e.target.value
    });
    let harvestStateValuesArray = [];
    //eslint-disable-next-line
    {fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
      seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (

      chartYearChecked.get(seriesVal.name) &&
        (
          this.addUniqueValue(harvestStateValuesArray, seriesHarvestData.harvest)
        )
    ))))}
    this.setState({
      harvestStateValues: harvestStateValuesArray
    })
    /*const { chartYearChecked } = this.state;
    this.setState({ chartYearChecked: chartYearChecked.set(year, e) });*/
    this.setState({
      loadingChart: true,
      customColors: null
    });
    setTimeout(() => {  this.updateChartSeries(); }, 100);
  };

  seasonSelectChange = (e) => {
    this.setState({
      selectSeasonState: e.target.value
    })
  };

  /** Changes when the Graph has been selected */
  chartGraphChange = (e,graph) => {
    const { chartGraphChecked } = this.state;
    this.setState({ chartGraphChecked: chartGraphChecked.set(graph, e.target.checked) });
    this.setState({
      loadingChart: true,
      customColors: null
    });
    setTimeout(() => {  this.updateChartSeries(); }, 100); 
  };

  /** Changes when the Graph has been selected */
  chartGraphClick = (e,graph) => {
    const { chartGraphChecked } = this.state;
    this.setState({ openDialogGraph: false });
    this.setState({ chartGraphChecked: chartGraphChecked.set(graph, false) });
    this.setState({
      loadingChart: true,
      customColors: null
    });
    setTimeout(() => {  this.updateChartSeries(); }, 100); 
  };

  onChangeStation = e => {
    const { fetchWeatherData, activeFarm } = this.props;
    const { paramId, selectedFieldId } = this.state;
    const formDataObject = { "fieldId": (selectedFieldId !== null ? selectedFieldId : paramId), "celciusDaysTarget": 475, "stationId": e.value };
    this.props.fieldChart.weatherData = null;
    this.setState({
      onChangeStation: true,
      selectStationState: e.target.value
    });
    fetchWeatherData(activeFarm.farm.id, formDataObject);
  }

  /** To get the year checkboxes **/
  getWeatherYearList = () => {
    const { fieldChart } = this.props;
    const { chartYearChecked } = this.state;
    if(fieldChart && fieldChart.weatherData !== null && fieldChart.weatherData.years !== null && chartYearChecked.size > 0) {
      return fieldChart.weatherData.years.map((year, index) => (
        <div key={year} className="field-weather-checkbox">
          <CheckboxControl
            label={year}
            onChange={e => this.chartYearChange(e,year) }
            checked={chartYearChecked.get(year)}
            controlId={year}
            styleName="field-weather-checkbox-content"
          />
        </div>
      ))
    }
  };

  selectHarvestValue = (harvest) => {
    const { harvest1State, harvest2State, harvest3State, harvest4State } = this.state;
    if(harvest === 1) {
      this.setState({
        harvest1State: !harvest1State
      });
    } else if(harvest === 2) {
      this.setState({
        harvest2State: !harvest2State
      });      
    } else if(harvest === 3) {
      this.setState({
        harvest3State: !harvest3State
      });      
    } else if(harvest === 4) {
      this.setState({
        harvest4State: !harvest4State
      });
    }
  }

  /** To get the stations */
  getStationsList = () => {
    const { fieldChart, intl } = this.props;
    let defaultStation = {};
    if(fieldChart && fieldChart.weatherData !== null && fieldChart.weatherData.stations !== null) {
      defaultStation = { "value": fieldChart.weatherData.stations[0].stationId, "label": fieldChart.weatherData.stations[0].stationName };
    }

    if(fieldChart && fieldChart.weatherData !== null && fieldChart.weatherData.stations !== null) {
      return (
        <div className="dropdowns">
          <Field
            styleName="weather-checkbox"
            component={SelectControl}
            name="station"
            controlId="station"
            defaultValue={defaultStation}
            label={intl.formatMessage({ id: 'WEATHER_STATION_NAME' })}
            options={getDropDownWeatherStations(fieldChart.weatherData.stations)}
            onChange={e => this.onChangeStation(e)}
          />
        </div>
      )   
    }
  };

  /** To get the graph checkboxes */
  getWeatherGraphList = () => {
    const { fieldChart } = this.props;
    const { chartGraphChecked } = this.state;
    if(fieldChart && fieldChart.weatherData !== null && fieldChart.weatherData.graphs !== null && chartGraphChecked.size > 0) {
      return fieldChart.weatherData.graphs.map((graph, index) => (
        <div key={graph} className="field-weather-checkbox">
          <CheckboxControl
            label={graph}
            onChange={e => this.chartGraphChange(e, "graph_"+index)}
            value={chartGraphChecked.get("graph_"+index)}
            checked={chartGraphChecked.get("graph_"+index)}
            controlId={"graph_"+index}
            styleName="field-weather-checkbox-content"
          />
        </div>
      ))      
    }
  };

  /** To get the graph checkboxes */
  getChartHeader = (index) => {
    const { fieldChart } = this.props;
    const { chartGraphChecked } = this.state;
    if(chartGraphChecked.get("graph_"+index)) {
      return (
        <div className="chart-title-div">
          <span className="chart-title">&nbsp;</span>
          <span className="chart-year">
            {index === 0 ? <span className="circle-title">{fieldChart.weatherData && fieldChart.weatherData.temperatureSumChart.yaxis.title.text}</span> :
            index === 1 ? <span className="circle-title">{fieldChart.weatherData && fieldChart.weatherData.meanTemperatureChart.yaxis.title.text}</span> :
            index === 2 ? <span className="circle-title">{fieldChart.weatherData && fieldChart.weatherData.dailyRainfallChart.yaxis.title.text}</span> :
            index === 3 ? <span className="circle-title">{fieldChart.weatherData && fieldChart.weatherData.rawProteinChart.yaxis.title.text}</span> :
            <span className="circle-title">{fieldChart.weatherData && fieldChart.weatherData.omdChart.yaxis.title.text}</span>}
            {this.getChartHeaderYear(index)}
          </span>
        </div>
      )
    }
  };

  /** To get the graph checkboxes */
  getRaProteinChartHeader = (index) => {
    const { intl } = this.props;
    return (
      <div className="chart-title-div">
        <span className="chart-title">&nbsp;</span>
        <span className="chart-year">
          <span className="circle-title">{intl.formatMessage({ id: 'WEATHER_RAPROTEIN_YAXIS' })}</span>
          {this.getChart2HeaderYear(index)}
        </span>
      </div>
    )
  };

  /** To get the graph checkboxes */
  getOmdChartHeader = (index) => {
    const { intl } = this.props;
    return (
      <div className="chart-title-div">
        <span className="chart-title">{intl.formatMessage({ id: 'WEATHER_RAPROTEIN_OMD_TITLE' })}&nbsp;</span>
        <span className="chart-year">
          <span className="circle-title">{intl.formatMessage({ id: 'WEATHER_OMD_YAXIS' })}</span>
          {this.getChart2HeaderYear(index)}
        </span>
      </div>
    )
  };

  getChartHeaderYear = (indexValue) => {
    const { fieldChart } = this.props;
    const { chartYearChecked, tempSumLegendColor, meanTempLegendColor, dailyRainfallLegendColor, raproteinLegendColor, omdLegendColor } = this.state;
    if(fieldChart && fieldChart.weatherData !== null && fieldChart.weatherData.years !== null && chartYearChecked.size > 0) {
      if(indexValue === 0) {
        return fieldChart.weatherData.years.map((year, index) => (
          chartYearChecked.get(year) ?
            <span key={year} className="chart-year-inner">
              <span>
                <div className="circle-dot" style={{backgroundColor: tempSumLegendColor.get(year)}}></div>
              </span>
              <span className="circle-font">{year}</span>
            </span>
          : ''
        ))
      } else if(indexValue === 1) {
        return fieldChart.weatherData.years.map((year, index) => (
          chartYearChecked.get(year) ?
            <span key={year} className="chart-year-inner">
              <span>
                <div className="circle-dot" style={{backgroundColor: meanTempLegendColor.get(year)}}></div>
              </span>
              <span className="circle-font">{year}</span>
            </span>
          : ''
        ))
      } else if(indexValue === 2) {
        return fieldChart.weatherData.years.map((year, index) => (
          chartYearChecked.get(year) ?
            <span key={year} className="chart-year-inner">
              <span>
                <div className="circle-dot" style={{backgroundColor: dailyRainfallLegendColor.get(year)}}></div>
              </span>
              <span className="circle-font">{year}</span>
            </span>
          : ''
        ))
      } else if(indexValue === 3) {
        return fieldChart.weatherData.years.map((year, index) => (
          chartYearChecked.get(year) ?
            <span key={year} className="chart-year-inner">
              <span>
                <div className="circle-dot" style={{backgroundColor: raproteinLegendColor.get(year)}}></div>
              </span>
              <span className="circle-font">{year}</span>
            </span>
          : ''
        ))
      } else {
        return fieldChart.weatherData.years.map((year, index) => (
          chartYearChecked.get(year) ?
            <span key={year} className="chart-year-inner">
              <span>
                <div className="circle-dot" style={{backgroundColor: omdLegendColor.get(year)}}></div>
              </span>
              <span className="circle-font">{year}</span>
            </span>
          : ''
        ))
      }     
    }
  };

  getChart2HeaderYear = (indexValue) => {
    const { fieldChart } = this.props;
    const { chartYearChecked, raProteinLegendColor, omdLegendColor } = this.state;
    if(fieldChart && fieldChart.weatherData !== null && fieldChart.weatherData.years !== null && chartYearChecked.size > 0) {
      if(indexValue === 3) {
        return fieldChart.weatherData.years.map((year, index) => (
          chartYearChecked.get(year) ?
            <span key={year} className="chart-year-inner">
              <span>
                <div className="circle-dot" style={{backgroundColor: omdLegendColor.get(year)}}></div>
              </span>
              <span className="circle-font">{year}</span>
            </span>
          : ''
        ))
      } else {
        return fieldChart.weatherData.years.map((year, index) => (
          chartYearChecked.get(year) ?
            <span key={year} className="chart-year-inner">
              <span>
                <div className="circle-dot" style={{backgroundColor: raProteinLegendColor.get(year)}}></div>
              </span>
              <span className="circle-font">{year}</span>
            </span>
          : ''
        ))
      }     
    }
  };

  addUniqueValue = (arr, value) => {
    if (!arr.includes(value)) {
      arr.push(value);
    }
    return arr;
  };

  render() {
    const { fieldChart, intl } = this.props;
    const { series, customColors, strokeDashArray, paramId, yAxisSeries, discreteArray, discreteOmdArray, markerSizeArray, chartGraphChecked,
      chartYearChecked, previousIndex, nextIndex, previousIndexName, nextIndexName, harvestFieldValues, selectedFieldCheckbox, selectedFieldId, selectedYearValues, harvest1State, 
      harvest2State, harvest3State, harvest4State, openDialogGraph, harvestStateValues } = this.state;
    let dateValue = 0;
    let currentYear = new Date().getFullYear();
    if(chartYearChecked.size > 0) {
      fieldChart.weatherData && fieldChart.weatherData.years.map((year, index) => (
        chartYearChecked.get(year) ? dateValue = dateValue + 1 : ''
      ))
    }
    let harvestDataLength = 0;
    if(fieldChart && fieldChart.weatherData && fieldChart.weatherData.temperatureSumChart) {
      fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
        seriesVal && seriesVal.harvestData && seriesVal.harvestData.map(seriesHarvestData => (
          chartYearChecked.get(seriesVal.name) ?
          harvestDataLength = harvestDataLength+1 : ''
        ))
      ))
    }

    if(fieldChart && fieldChart.weatherData && fieldChart.weatherData.temperatureSumChart) {
      fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
        seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (
          harvestDataLength > 0 && chartYearChecked.get(seriesVal.name) &&
          <tbody key={index}>
            <tr>
              <td className="weather-table-td-bottom-right weather-table-td-left">
                {seriesHarvestData.harvestDate.split("-")[2]}-{seriesHarvestData.harvestDate.split("-")[1]}-{seriesHarvestData.harvestDate.split("-")[0]}
              </td>
            </tr>
          </tbody>
        ))
      ))
    }
    
    if(fieldChart && fieldChart.weatherData && fieldChart.weatherData.temperatureSumChart) {
      fieldChart.weatherData.temperatureSumChart.series && fieldChart.weatherData.temperatureSumChart.series.map(seriesVal => (
        seriesVal && seriesVal.harvestData && seriesVal.harvestData.map((seriesHarvestData,index) => (
          harvestDataLength > 0 && chartYearChecked.get(seriesVal.name) &&
          <tbody key={index}>
            <tr>
              <td className="weather-table-td-bottom-right">
                {seriesHarvestData.harvest}
              </td>
              <td className="weather-table-td-bottom-right">
                {seriesVal.data[seriesVal.harvestDateIndexes[index]]}
              </td>
              <td className="weather-table-td-bottom-right">
                {seriesVal.tempSumDiff === undefined ? '' : seriesVal.tempSumDiff[index] === 0 ? '' : seriesVal.tempSumDiff[index]}
              </td>
              <td className="weather-table-td-bottom-right">
                {seriesHarvestData.totalHarvest} {seriesHarvestData.units === 'bales' && intl.formatMessage({ id: 'FIELD_WEATHER_BALES' })}{seriesHarvestData.units === 'wagon' && intl.formatMessage({ id: 'FIELD_WEATHER_WAGON' })}
              </td>
              <td className="weather-table-td-bottom-right">
                {seriesHarvestData.drymatter}
              </td>
              <td className="weather-table-td-bottom-right">
                {seriesHarvestData.rawProtein}
              </td>
              <td className="weather-table-td-bottom-right">
                {seriesHarvestData.digestibility}
              </td>
              <td className="weather-table-td-bottom-right">
                {seriesHarvestData.totalDrymatter}
              </td>
              <td className="weather-table-td-bottom-right">
                {parseInt(currentYear) === parseInt(seriesVal.name) ? seriesHarvestData.yieldGoal : ''}
              </td>
              <td className="weather-table-td-bottom-right">
                {parseInt(currentYear) === parseInt(seriesVal.name) ? seriesHarvestData.difference : ''}
              </td>
            </tr>
          </tbody>
        ))
      ))
    }
    if(fieldChart.weatherData !== null && customColors != null) {
      var options = {
        chart: OPTIONS_CHART,
        legend: {
          show: false
        },
        colors: customColors,
        stroke: {
          width: 4,
          curve: 'smooth',
          dashArray: strokeDashArray
        },
        tooltip: {
          inverseOrder: true,
          y: {
            formatter: function(value, { seriesIndex, dataPointIndex, w }) { 
              let returnValue = value;
              let OMDValue = "";
              if(discreteArray.length > 0 || discreteOmdArray.length > 0) {
                discreteOmdArray.length > 0 && discreteOmdArray.filter(discrete => discrete.seriesIndex === seriesIndex && discrete.dataPointIndex === dataPointIndex).map(discreteValue => (
                  //eslint-disable-next-line
                  discreteValue && value !== null ? returnValue = value + ' °C - ' + intl.formatMessage({ id: 'OMD_LABEL' }) + discreteValue.dataValue + ' %' : returnValue = value,
                  discreteValue && value !== null ? OMDValue = intl.formatMessage({ id: 'OMD_LABEL' }) + discreteValue.dataValue + ' %' : OMDValue = value
                ));
                discreteArray.length > 0 && discreteArray.filter(discrete => discrete.seriesIndex === seriesIndex && discrete.dataPointIndex === dataPointIndex).map(discreteValue => (
                  returnValue.toString().includes('OMD:') ?
                  discreteValue && value !== null ? returnValue = value + ' (' + fieldChart.weatherData.harvestDateTooltip + ') °C - ' + OMDValue : returnValue = value :
                  discreteValue && value !== null ? returnValue = value + ' (' + fieldChart.weatherData.harvestDateTooltip + ')' : returnValue = value
                ));
                if(returnValue !== null) {
                  if(returnValue.toString().includes('OMD:')) {
                    if(series[seriesIndex].graphType === 'graph_0') {
                      return (returnValue + ((series[seriesIndex].harvestTempSum && series[seriesIndex].harvestTempSum.length > 0  
                        && series[seriesIndex].harvestTempSum[dataPointIndex] !== null && series[seriesIndex].harvestTempSum[dataPointIndex] !== undefined)
                        ? (' - ' + series[seriesIndex].harvestTempSum[dataPointIndex]) : ''));
                    } else {
                      return returnValue;
                    }
                  } else if(series[seriesIndex] && series[seriesIndex].tooltip) {
                    if(series[seriesIndex].graphType === 'graph_0') {
                      return (returnValue + series[seriesIndex].tooltip + ((series[seriesIndex].harvestTempSum && series[seriesIndex].harvestTempSum.length > 0  
                        && series[seriesIndex].harvestTempSum[dataPointIndex] !== null && series[seriesIndex].harvestTempSum[dataPointIndex] !== undefined)
                        ? (' - ' + series[seriesIndex].harvestTempSum[dataPointIndex]) : ''));
                    } else {
                      return returnValue + series[seriesIndex].tooltip;
                    }
                  } else {
                    if(series[seriesIndex].graphType === 'graph_0') {
                      return (returnValue + ' °C' + ((series[seriesIndex].harvestTempSum && series[seriesIndex].harvestTempSum.length > 0  
                        && series[seriesIndex].harvestTempSum[dataPointIndex] !== null && series[seriesIndex].harvestTempSum[dataPointIndex] !== undefined)
                        ? (' - ' + series[seriesIndex].harvestTempSum[dataPointIndex]) : ''));
                    } else {
                      return returnValue + ' °C';
                    }
                  }
                } else {
                  return returnValue;
                }
              } else {
                if(returnValue !== null) {
                  if(returnValue.toString().includes('OMD:')) {
                    if(series[seriesIndex].graphType === 'graph_0') {
                      return (returnValue + ((series[seriesIndex].harvestTempSum && series[seriesIndex].harvestTempSum.length > 0  
                        && series[seriesIndex].harvestTempSum[dataPointIndex] !== null && series[seriesIndex].harvestTempSum[dataPointIndex] !== undefined)
                        ? (' - ' + series[seriesIndex].harvestTempSum[dataPointIndex]) : ''));
                    } else {
                      return returnValue;
                    }
                  } else if(series[seriesIndex] && series[seriesIndex].tooltip) {
                    if(series[seriesIndex].graphType === 'graph_0') {
                      return (returnValue + series[seriesIndex].tooltip + ((series[seriesIndex].harvestTempSum && series[seriesIndex].harvestTempSum.length > 0  
                        && series[seriesIndex].harvestTempSum[dataPointIndex] !== null && series[seriesIndex].harvestTempSum[dataPointIndex] !== undefined)
                        ? (' - ' + series[seriesIndex].harvestTempSum[dataPointIndex]) : ''));
                    } else {
                      return returnValue + series[seriesIndex].tooltip;
                    }
                  } else {
                    if(series[seriesIndex].graphType === 'graph_0') {
                      return (returnValue + ((series[seriesIndex].harvestTempSum && series[seriesIndex].harvestTempSum.length > 0  
                        && series[seriesIndex].harvestTempSum[dataPointIndex] !== null && series[seriesIndex].harvestTempSum[dataPointIndex] !== undefined)
                        ? (' - ' + series[seriesIndex].harvestTempSum[dataPointIndex]) : ''));
                    } else {
                      return returnValue;
                    }
                  }
                } else {
                  return returnValue;
                }
              }
            }
          }
        },
        markers: {
          colors: customColors,
          strokeColors: customColors,
          hover: {
            size: 4,
          },
          size: markerSizeArray,
          showNullDataPoints: false,
          shape: 'circle',
          discrete: discreteArray !== null && discreteArray.length > 0 ? discreteOmdArray.concat(discreteArray) : discreteOmdArray
        },
        xaxis: {
          type: 'category',
          categories: fieldChart.weatherData.xaxis.categories,
          tickAmount: XAXIS_TICK_AMOUNT,
          labels: OPTIONS_XAXIS_LABELS,
          title: {
            text: fieldChart.weatherData.xaxis.title.text,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: OPTIONS_XAXIS_TITLE_STYLE
          }
        },
        yaxis: yAxisSeries
      };
    }
    const commonText = {
      fontFamily: 'Inter',
      fontSize: '14px',
      textAlign: 'center',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '20px',
      letterSpacing: '0.1px'
    }
    const titleTextButton = {
      ...commonText,
      color: '#041F21',
      textTransform: 'Capitalize',
      width: '120px'
    }
    const buttonFilledLeftStyle = {
      borderTopLeftRadius: '20px',
      borderBottomLeftRadius: '20px',
      border: '1px solid #BEC8C9'
    }
    const buttonFilledLeftStyle1 = {
      borderTopLeftRadius: '20px',
      borderBottomLeftRadius: '20px',
      border: '1px solid #BEC8C9',
      backgroundColor: '#BFD9DB'
      
    }
    const buttonFilledRightStyle = {
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
      border: '1px solid #BEC8C9'
    }
    const buttonFilledRightStyle1 = {
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
      border: '1px solid #BEC8C9',
      backgroundColor: '#BFD9DB'
    }
    const dialogTitleStyle = { fontFamily: 'Inter', fontWeight: 400, fontSize: "24px", fontStyle: "normal", padding: "25px 30px 20px 30px", textAlign: "left", color: "#191C1C" }
    const dialogTitleDialogStyle = { fontFamily: 'Inter', fontWeight: 400, fontSize: "22px", fontStyle: "normal", padding: "25px 30px 20px 30px", textAlign: "left", color: "#191C1C" }
    const dialogSubTitleStyle = { ...dialogTitleStyle, fontSize: "14px", fontStyle: "normal", color: "#3F4949", paddingLeft: "30px", paddingRight: "30px", paddingBottom: "20px", paddingTop: "0px"  }
    const scrollStyle = {
      maxHeight: '400px',
      overflowY: 'auto',
      WebkitOverflowScrolling: 'touch',
      scrollbarWidth: 'thin',
      scrollbarColor: '#6F7979 #E6E9E8'
    }
    const inputStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: "14px", padding: "0px 30px" };
    const chipStyleSelected = {
      display: 'flex',
      height: '32px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      padding: '6px 16px',
      gap: '8px',
      background: '#CCE8E9',
      color: '#041F21',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '0.1px'
    }
    const chipStyleNotSelected = {
      display: 'flex',
      height: '32px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      border: '1px solid #707979',
      padding: '6px 16px',
      gap: '8px',
      background: '#F7FAF9',
      color: '#041F21',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '0.1px'
    }
    const colorCircle = {
      width: '15px',
      height: '15px',
      borderRadius: '50%',
      color: '#041F21'
    }
    const chipStyle = {
      display: 'flex',
      height: '32px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      background: '#CCE8E9',
      color: '#041F21',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '20px',
      letterSpacing: '0.1px'
    }
    const centerAlignStyle = {
      height: '72px',
      paddingTop: '20px',
      zIndex: 1
    };
    const centerAlignStyle1 = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    };
    const linkStyle = { color: '#00696D', fontSize: '14px', fontFamily: 'Inter', textTransform: 'none' };
    const linkStyleDisabled = { color: '#cccccc', fontSize: '14px', fontFamily: 'Inter', textTransform: 'none' };
    const linkContainerStyle = { display: 'flex', alignItems: 'center' };
    const arrowStyle = { color: '#00696D', width: '18px', height: '18px' };
    const arrowStyleLeftDisabled = { color: '#cccccc', width: '18px', height: '18px', marginRight: 10 };
    const arrowStyleRightDisabled = { ...arrowStyleLeftDisabled, marginLeft: 10 };
    return (
      <div>
        {fieldChart && fieldChart.isLoading ? <div className="field-area" style={{ zIndex: 10, minHeight: '75vh' }}><Loader styleName="fixed-loader" /></div> : 
        (fieldChart && fieldChart.errorMessage !== null) ? <div className="edit-field-newcontainer" style={{ minHeight: '75vh' }}>{fieldChart.errorMessage.data}</div> :
        <Box Component='div' sx={{ minHeight: '75vh'}}>
          <WeatherHeader
            fieldChart={fieldChart}
            openOmdDialogBox={this.openOmdDialogBox}
          />
          <Box Component='div' sx={{textAlign: 'center', paddingTop: '50px'}}>
            <Box sx={{ flexGrow: 1 }}>
              <ToggleButtonGroup
                exclusive
                aria-label="text alignment"
                value={this.state.selectedValue}
                onChange={this.handleAlignment}
              >
                {this.state.selectedValue === 'first' ?
                  <ToggleButton value="first" style={buttonFilledLeftStyle1}>
                    <Typography style={titleTextButton}>{intl.formatMessage({ id: 'WEATHER_TOGGLE_BUTTON_1' })}</Typography>
                  </ToggleButton> :
                  <ToggleButton value="first" style={buttonFilledLeftStyle}>
                    <Typography style={titleTextButton}>{intl.formatMessage({ id: 'WEATHER_TOGGLE_BUTTON_1' })}</Typography>
                  </ToggleButton>
                }
                {this.state.selectedValue === 'second' ?
                  <ToggleButton value="second" style={buttonFilledRightStyle1}>
                    <Typography style={titleTextButton}>{intl.formatMessage({ id: 'WEATHER_TOGGLE_BUTTON_2' })}</Typography>
                  </ToggleButton> :
                  <ToggleButton value="second" style={buttonFilledRightStyle}>
                    <Typography style={titleTextButton}>{intl.formatMessage({ id: 'WEATHER_TOGGLE_BUTTON_2' })}</Typography>
                  </ToggleButton>
                }
              </ToggleButtonGroup>
              {this.state.selectedValue === 'first' &&
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ flexGrow: 1, paddingTop: '50px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <Grid container sx={{ width: 'auto', float: 'left', paddingTop: '25px' }}>
                        <Grid item xs={12} md={10}>
                          <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', paddingTop: '10px' }}>
                            {fieldChart && fieldChart.weatherData !== null && fieldChart.weatherData.graphs !== null && chartGraphChecked.size > 0 &&
                              fieldChart.weatherData.graphs.map((graph, index) => (
                                chartGraphChecked.get("graph_"+index) &&
                                <Chip
                                  label={graph}
                                  style={chipStyle}
                                  onDelete={e => this.chartGraphClick(e, "graph_"+index)}
                                  deleteIcon={<CloseIcon style={colorCircle}/>}
                                />
                              )
                            )}</Stack></Grid>
                            <Grid item xs={12} md={2}>
                            {fieldChart && fieldChart.weatherData !== null && fieldChart.weatherData.graphs !== null && chartGraphChecked.size > 0 &&
                            <Stack direction="row" spacing={2}>
                              <div>
                                <Button
                                  ref={this.anchorRef}
                                  id="composition-button"
                                  aria-controls={openDialogGraph ? 'composition-menu' : undefined}
                                  aria-expanded={openDialogGraph ? 'true' : undefined}
                                  aria-haspopup="true"
                                  onClick={this.handleToggle}
                                  endIcon={<ArrowDropDown />}
                                  sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                    letterSpacing: '0.1px',
                                    color:'#3F4949',
                                    textTransform: 'capitalize',
                                    paddingTop: '15px',
                                    paddingLeft: '20px'
                                  }}
                                >
                                  Visninger
                                </Button>
                                <Popper
                                  open={openDialogGraph}
                                  anchorEl={this.anchorRef.current}
                                  role={undefined}
                                  placement="bottom-start"
                                  transition
                                  disablePortal
                                  sx={{zIndex: 10}}
                                >
                                  {({ TransitionProps, placement }) => (
                                    <Grow
                                      {...TransitionProps}
                                      style={{
                                        transformOrigin:
                                          placement === 'bottom-start' ? 'left top' : 'left bottom',
                                      }}
                                    >
                                      <Paper>
                                        <ClickAwayListener onClickAway={this.handleClose}>
                                          <MenuList
                                            autoFocusItem={openDialogGraph}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                            onKeyDown={this.handleListKeyDown}
                                          >
                                            {fieldChart.weatherData.graphs.map((graph, index) => (
                                              <MenuItem key={graph} value={graph} style={{ border: 'none', backgroundColor: 'transparent' }}>
                                                <Checkbox 
                                                  sx={{ color: '#6F7979', '&.Mui-checked': { color: '#00696D' } }}
                                                  label={graph}
                                                  onChange={e => this.chartGraphChange(e, "graph_"+index)}
                                                  checked={chartGraphChecked.get("graph_"+index)}
                                                  controlId={"graph_"+index}
                                                />
                                                <ListItemText primary={graph} sx={{ textAlign: 'left', paddingLeft: '20px' }} />
                                              </MenuItem>
                                            ))}
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Paper>
                                    </Grow>
                                  )}
                                </Popper>
                              </div>
                            </Stack>}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Grid container sx={{ width: 'auto', float: 'right' }}>
                        <Grid item>
                          {fieldChart && fieldChart.weatherData !== null && fieldChart.weatherData.years !== null && chartYearChecked.size > 0 &&
                          <MuiMultiSelect 
                            name="selectedYear"
                            label='År'
                            controlId="selectedYear"
                            options={fieldChart.weatherData.years}
                            value={selectedYearValues}
                            onChange={this.chartYearChange}
                          />}
                        </Grid>
                        <Grid item>
                          {fieldChart && fieldChart.weatherData !== null && fieldChart.weatherData.stations !== null &&
                          <MuiRadioButton 
                            name="selectStation"
                            label='Værstasjon'
                            controlId="selectStation"
                            options={getDropDownWeatherStations(fieldChart.weatherData.stations)}
                            value={this.state.selectStationState}
                            onChange={e => this.onChangeStation(e)}
                            InputLabelProps={{ shrink: true }}
                          />}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ flexGrow: 1, paddingTop: '10px' }}>
                  <Card variant="outlined">
                    <CardContent>
                      <div className="edit-field-newcontainer">
                        {options !== null && series !== null && customColors != null && options !== undefined ? 
                          (<div className="div-flex-width"><div className="field-area">
                            {this.getChartHeader(0)}
                            {this.getChartHeader(1)}
                            {this.getChartHeader(2)}
                            {this.getChartHeader(3)}
                            {this.getChartHeader(4)}
                            <div className="field-padding-top">
                              <Chart
                                options={options}
                                series={series}
                                type="line"
                                width="100%"
                              />
                            </div>
                          </div>
                        </div>) : <div className="div-flex-width"><Loader styleName="fixed-loader" /></div> }
                      </div>
                    </CardContent>
                  </Card>
                </Box>
              </Box>}
              {this.state.selectedValue === 'second' &&
              <Box sx={{ flexGrow: 1 }}>
                <Box sx={{ flexGrow: 1, paddingTop: '40px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={10}>
                      <Stack direction="row" spacing={1} sx={{ paddingTop: '25px' }}>
                       {harvestStateValues.includes(1) ? (harvest1State ? 
                        <Chip
                          label="1. slått"
                          style={chipStyleSelected}
                          onClick={() => this.selectHarvestValue(1)}
                        /> : 
                        <Chip
                          label="1. slått"
                          style={chipStyleNotSelected}
                          onClick={() => this.selectHarvestValue(1)}
                        />) : ''}
                        {harvestStateValues.includes(2) ? (harvest2State ? 
                        <Chip
                          label="2. slått"
                          style={chipStyleSelected}
                          onClick={() => this.selectHarvestValue(2)}
                        /> : 
                        <Chip
                          label="2. slått"
                          style={chipStyleNotSelected}
                          onClick={() => this.selectHarvestValue(2)}
                        />) : ''}
                        {harvestStateValues.includes(3) ? (harvest3State ? 
                        <Chip
                          label="3. slått"
                          style={chipStyleSelected}
                          onClick={() => this.selectHarvestValue(3)}
                        /> : 
                        <Chip
                          label="3. slått"
                          style={chipStyleNotSelected}
                          onClick={() => this.selectHarvestValue(3)}
                        />) : ''}
                        {harvestStateValues.includes(4) ? (harvest4State ? 
                        <Chip
                          label="4. slått"
                          style={chipStyleSelected}
                          onClick={() => this.selectHarvestValue(4)}
                        /> : 
                        <Chip
                          label="4. slått"
                          style={chipStyleNotSelected}
                          onClick={() => this.selectHarvestValue(4)}
                        />) : ''}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      {fieldChart && fieldChart.weatherData !== null && fieldChart.weatherData.years !== null && chartYearChecked.size > 0 &&
                      <MuiMultiSelect 
                        name="selectedYear"
                        label='År'
                        controlId="selectedYear"
                        options={fieldChart.weatherData.years}
                        value={selectedYearValues}
                        onChange={this.chartYearChange}
                      />}
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Card variant="outlined">
                        <CardContent>
                          <WeatherTab2 
                            fieldChart={fieldChart}
                            chartYearChecked={chartYearChecked}
                            harvestDataLength={harvestDataLength}
                            chartGraphChecked={chartGraphChecked}
                            currentYear={currentYear}
                            harvest1State={harvest1State}
                            harvest2State={harvest2State}
                            harvest3State={harvest3State}
                            harvest4State={harvest4State}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              </Box>}
            </Box>
          </Box>
        </Box>}
        <Dialog onClose={() => {}} open={this.state.openOmdBox} PaperProps={{
          style: {
            backgroundColor: '#E6E9E8',
            borderRadius: "28px",
            width: "457px",
            minWidth: "320px",
            maxWidth: "560px",
          }}}>
          <DialogTitle style={dialogTitleDialogStyle} sx={{}}>
            {intl.formatMessage({ id: 'OMD_TITLE' })}
            <Box component="div" sx={{fontSize: '14px'}}>
              ({intl.formatMessage({ id: 'OMD_SUB_TITLE' })})
            </Box>          
          </DialogTitle>
          <Box component="div" style={scrollStyle}>
            <Box component="div" style={dialogSubTitleStyle}>
              <FormControl>
                <RadioGroup column name="omdValue" value={this.state.omdGraphValue} onChange={this.handleOmdChange}>
                  <FormControlLabel value='1' control={<Radio sx={{ color: '#6F7979', '&.Mui-checked': { color: '#00696D' }}} />} label='Svært høy (80% og høyere)' />
                  <FormControlLabel value='2' control={<Radio sx={{ color: '#6F7979', '&.Mui-checked': { color: '#00696D' }}} />} label='Høy (75% - 79%)' />
                  <FormControlLabel value='3' control={<Radio sx={{ color: '#6F7979', '&.Mui-checked': { color: '#00696D' }}} />} label='Medium (70% - 74%)' />
                  <FormControlLabel value='4' control={<Radio sx={{ color: '#6F7979', '&.Mui-checked': { color: '#00696D' }}} />} label='Lav (65% - 69%)' />
                  <FormControlLabel value='5' control={<Radio sx={{ color: '#6F7979', '&.Mui-checked': { color: '#00696D' }}} />} label='Svært lav (64% og lavere)' />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box style={inputStyle} sx={{paddingLeft: '20px', paddingRight: '20px'}}>
              <Box component="div" sx={{border: '1px solid #c1c1c1'}}></Box>
            </Box>
            <Box component="div">
              <div className="modal-storage-list-details" style={{paddingTop: '20px'}}>
                <ul className="storage-list-wrapper">
                    <li className={`modal-storage-list-item1 full-width`}>
                      <div className="storage-details" style={{border: 'none', paddingLeft: '30px'}}>
                        <div className="storage-arrow-omd">
                          <span className="location-type-checkbox">
                            <FormControlLabel 
                              control={
                                <Checkbox
                                  checked={this.state.selectAllCheckbox}
                                  name="checkAll"
                                  onChange={() => this.checkAllFields()}
                                  sx={{ color: '#6F7979', '&.Mui-checked': { color: '#00696D' }}}
                                />
                              }
                              label={intl.formatMessage({ id: 'ALL' })}
                            />
                          </span>
                        </div>
                      </div>
                    </li>
                </ul>
              </div>
              {harvestFieldValues && 
                <div className="modal-storage-list-details" style={{paddingTop: '20px'}}>
                  <ul className="storage-list-wrapper">
                    {harvestFieldValues.map((field, index) => (
                      field.id === (selectedFieldId !== null ? selectedFieldId : paramId) ?
                      <li className={`modal-storage-list-item1 full-width`}>
                        <div className="storage-details" style={{border: 'none', paddingLeft: '30px'}}>
                          <FormControlLabel 
                            control={
                              <Checkbox
                                id={`${field.id}`}
                                checked={true}
                                name={`${field.id}`}
                                sx={{ color: '#6F7979', '&.Mui-checked': { color: '#00696D' }}}
                              />
                            }
                            label={field.name}
                          />
                        </div>
                      </li> : <li className={`modal-storage-list-item1 full-width`} sx={{ padding: '0px 5px !important'}} key={index+1}>
                        <div className="storage-details" style={{border: 'none', paddingLeft: '30px'}}>
                          <FormControlLabel 
                            control={
                              <Checkbox
                                id={`${field.id}`}
                                checked={selectedFieldCheckbox.length > 0 ? (selectedFieldCheckbox.length > 0 && _.filter(selectedFieldCheckbox, ['id', field.id])[0]).show : false}
                                name={`${field.id}`}
                                sx={{ color: '#6F7979', '&.Mui-checked': { color: '#00696D' }}}
                                onChange={e => this.checkBoxChange(field.id)}
                              />
                            }
                            label={field.name}
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              }
            </Box>
            {this.state.omdErrorMessage &&
            <Box style={inputStyle} sx={{paddingLeft: '20px', paddingRight: '20px'}}>
              <div style={{paddingTop: '10px', paddingBottom: '10px', color: '#cc0000'}}>{intl.formatMessage({ id: 'OMD_ERROR_MESSAGE' })}</div>
            </Box>}
            <div style={{padding: "15px 25px 25px 25px", textAlign: "right"}} className="fixed-button-popup">
              <span>
                <Chip
                  label={intl.formatMessage({ id: 'cancel' })}
                  clickable
                  style={buttonStyle}
                  sx={{ backgroundColor: 'transparent',
                    '&:hover, &:focus': {
                      backgroundColor: 'transparent',
                    },
                    fontSize: '18px',
                    fontWeight: '800'
                  }}
                  disableRipple
                  onClick={this.closeOmdDialogBox}
                />
                <Chip
                  label={intl.formatMessage({ id: 'OMD_OK' })}
                  clickable
                  style={buttonStyle}
                  sx={{ backgroundColor: 'transparent',
                    '&:hover, &:focus': {
                      backgroundColor: 'transparent',
                    },
                    fontSize: '18px',
                    fontWeight: '800'
                  }}
                  disableRipple
                  onClick={this.handleSubmitModel}
                />
              </span>
            </div>
          </Box>
        </Dialog>
        <div style={centerAlignStyle} className="fixed-button-mui">
          <div style={centerAlignStyle1}>
            {previousIndex !== null ? 
            (<div style={linkContainerStyle}>
              <ArrowBackIcon style={arrowStyle} />
              <Button style={linkStyle} onClick={() => this.changeField(previousIndex)} type="submit">
                {previousIndexName}
                {/*intl.formatMessage({ id: 'PREVIOUS_FIELD' })*/}
              </Button>
            </div>) :
            (<div style={linkContainerStyle} sx={{disabled: true}}>
              <ArrowBackIcon style={arrowStyleLeftDisabled} />
              <span style={linkStyleDisabled}>{intl.formatMessage({ id: 'PREVIOUS_FIELD' })}</span>
            </div>)}
            {nextIndex !== null ?
            (<div style={linkContainerStyle}>
              <Button style={linkStyle} onClick={() => this.changeField(nextIndex)} type="submit">
                {nextIndexName}
                {/*intl.formatMessage({ id: 'NEXT_FIELD' })*/}
              </Button>
              <ArrowForwardIcon style={arrowStyle} />
            </div>) : 
            (<div style={linkContainerStyle} sx={{disabled: true}}>
              <ArrowForwardIcon style={arrowStyleRightDisabled} />
              <span style={linkStyleDisabled}>{intl.formatMessage({ id: 'NEXT_FIELD' })}</span>
            </div>)}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  fieldChart: state.fieldChart,
  userDetails: state.userDetails.data,
  fieldDetails: state.fieldDetails.data,
  fieldList: state.fieldDetails.data
});

function mapDispatchToProps(dispatch) {
  return {
    fetchWeatherData: (farmId, formDataObject) => dispatch(fieldChartActions.fieldTemperatureSum(farmId, formDataObject)),
    updateOmdValue: (farmId, fieldId, formDataObject) => dispatch(fieldChartActions.updateOmdValues(farmId, fieldId, formDataObject))
  };
}

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(Weather)));